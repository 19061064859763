import React, { useEffect, useState } from "react";
import "./ClientComponent.css";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Image, Divider } from "semantic-ui-react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Section from '../section/Section';
import WhyUsSection from "../WhyUsSection/WhyUsSection";
import StyleCategoriesSection from "../StyleCategoriesSection/StyleCategoriesSection";

import { useDispatch, useSelector } from 'react-redux';
import { updateDisplayHeaderAndFooter, updateLoading } from '../../redux/user/userSlice';


import ClipLoader from "react-spinners/ClipLoader";

import wigsTrio from "../../assets/daily.jpg";
import geometricImage from "../../assets/kentaPrint.jpg";
//landing page
function ClientComponent() {
  const dispatch = useDispatch();
  const controlValues = useSelector((state) => state.User.controlValues);

  useEffect(() => {
    dispatch(updateDisplayHeaderAndFooter({header:true,footer:true}))
    // dispatch(updateLoading(true))
  }, [])

  return (
    <>
      {<div className="client-box">
        {controlValues?.announcements?.display && <div className="box-announcment">
          <h3>{controlValues?.announcements?.message}</h3>
        </div>}
        <div className="box-banner">
          <Container>
            <Image src={wigsTrio} />
          </Container>
        </div>
        <Container className="mt-5">
          <Section header={'Popular Wigs'} display={'WIG'} filter={'discount'} />
        </Container>

        <div className="styleCategory-container">
          <Container className="mt-5">
            <StyleCategoriesSection />
          </Container>
        </div>

        <div className="body-banner" style={{backgroundImage: `url(${geometricImage})`}}  />

        <div className="overTop-container">
          <div className="overTop-title">
            <h1>Top Sellers</h1>
          </div>
        </div>

        <Container className="mt-5">
          <Section header={''} display={'WIGCREATOR'} filter={'all'} />
        </Container>

        <Container className="whyUs-container">
          <WhyUsSection />
        </Container>
      </div>}

    </>
  );
}

export default ClientComponent;