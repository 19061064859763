import React, { useEffect, useState, useRef,forwardRef,useCallback } from "react";
import "../Checkout.css"
import firestoreService from '../../../services/firestoreService';

import { useSelector, useDispatch } from 'react-redux';




function PaymentComponent(props) {

    const userId = useSelector((state) => state.User.userId)
    const userInfo = useSelector((state) => state.User.userInfo)
    
    useEffect(()=>{

    },[])


    return(
        <div>
        <div>
            <hr/>
<h1>Payment</h1>
        </div>
        </div>
    )
}

export default PaymentComponent;