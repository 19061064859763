import React from "react";
import "./StyleCategoriesSection.css";

import { Link } from "react-router-dom";

import frontalImage from "../../assets/frontal.jpeg";
import closureImage from "../../assets/closure.jpg";



function FrontalClosureComponent(){ 

    return (
        <div className="lace-container">
            <div className="square-lace">
                <div style={{ backgroundImage: `url(${frontalImage})` }} className="frontal-link">
                    <Link to="/wigs/frontal"><span>Frontal</span></Link>
                </div>
                <div style={{ backgroundImage: `url(${closureImage})` }} className="closure-link">
                    <Link to="/wigs/closure"><span>Closure</span></Link>
                </div>
            </div>
        </div>
    );
}

export default FrontalClosureComponent;