import React, { useEffect, useState, useRef, forwardRef, useCallback } from "react";
import "../Checkout.css"
import firestoreService from '../../../services/firestoreService';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, ErrorMessage, } from 'formik';
import { Form, Radio, Checkbox } from 'semantic-ui-react'
import { AddressValidationSchema } from '../CheckoutValidation';
import { Image, Button } from "semantic-ui-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Container from "react-bootstrap/Container";
import _ from 'lodash';




function DeliveryFormComponent(props) {
    const [deliveryData, setDeliveryData] = useState({deliveryMethod:null,firstName:null});
    const userId = useSelector((state) => state.User.userId)
    const deliveryFormRef = useRef();

    useEffect(() => {

    }, [])


    return (
        <div>
            <Container>
                {<Formik
                    innerRef={deliveryFormRef}
                    // isInitialValid={AddressValidationSchema}
                    initialValues={deliveryData}
                    // validationSchema={AddressValidationSchema}
                    onSubmit={((values) => {
                        props.updateStatus()
                        console.log('Delivery Shipping', values)
                        if (values?.deliveryMethod === 'shipping') {
                            firestoreService.updateUser('shippingAddress', values, userId);// updates the user with their shipping values
                        }
                        // handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: values })


                    })}

                >
                    {({ handleChange, handleBlur,handleSubmit,submitForm ,values, touched, errors, setFieldValue,isValid,isSubmitting }) => (

                        <Form>
                            <Col>
                                <div className=''>
                                    <h3 style={{ textAlign: 'center' }} > How would you like to get your order? </h3>
                                    <Row>

                                        <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Shipping'
                                                name='deliveryMethod'
                                                value='SHIPPING'
                                                checked={values?.deliveryMethod === 'SHIPPING'}
                                                onChange={(event, data) => {
                                                    console.log('as the mind follows deepr', values)
                                                    setFieldValue('deliveryMethod', data.value)
                                                    var localData = _.cloneDeep(values)
                                                    delete localData['deliveryMethod']; // removed old delivery method selected so it does not overwrite the new one



                                                    // handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: { deliveryMethod: 'SHIPPING', ...localData } }) // sets method to shipping if selected

                                                }}
                                            />
                                        </Form.Field>
                                        {/* <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Pickup'
                                                name='deliveryMethod'
                                                value='PICKUP'
                                                checked={values?.deliveryMethod === 'PICKUP'}
                                                onChange={(event, data) => { setFieldValue('deliveryMethod', data.value) }}
                                            />
                                        </Form.Field> */}
                                        {/* {props.wigCreatorInfo?.services?.acceptingAppointments && props.wigCreatorInfo?.services?.wigInstallation.length > 0 && <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Installation'
                                                name='deliveryMethod'
                                                value='INSTALLATION'
                                                checked={values?.deliveryMethod === 'INSTALLATION'}
                                                onChange={(event, data) => { setFieldValue('deliveryMethod', data.value) }}
                                            />
                                        </Form.Field>} */}
                                    </Row>
                                    <hr />
                                    {/* Shipping data */}
                                    {<>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>First Name</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'firstName'}
                                                    placeholder={'First Name'}
                                                    value={values?.firstName}
                                                    onChange={handleChange} />
                                                {touched.firstName && errors.firstName && <div className='validatonError' >{errors.firstName}</div>}

                                            </Col>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Last Name</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'lastName'}
                                                    placeholder={'Last Name'}
                                                    value={values?.lastName}
                                                    onChange={handleChange} />
                                                {touched.lastName && errors.lastName && <div className='validatonError' >{errors.lastName}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Address</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'address'}
                                                    placeholder={'Address'}
                                                    value={values?.address}
                                                    onChange={handleChange} />
                                                {touched.address && errors.address && <div className='validatonError' >{errors.address}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Apartment</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'addressTwo'}
                                                    placeholder={'Apartment or Suite Number'}
                                                    value={values?.addressTwo}
                                                    onChange={handleChange} />
                                                {touched.addressTwo && errors.addressTwo && <div className='validatonError' >{errors.addressTwo}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>City</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'city'}
                                                    placeholder={'City'}
                                                    value={values?.city}
                                                    onChange={handleChange} />
                                                {touched.city && errors.city && <div className='validatonError' >{errors.city}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Country</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'country'}
                                                    placeholder={'Country'}
                                                    value={values?.country}
                                                    onChange={handleChange} />
                                                {touched.country && errors.country && <div className='validatonError' >{errors.country}</div>}
                                            </Col>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Province</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'province'}
                                                    placeholder={'Province'}
                                                    value={values?.province}
                                                    onChange={handleChange} />
                                                {touched.province && errors.province && <div className='validatonError' >{errors.province}</div>}
                                            </Col>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Postal/Zip Code</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'postal'}
                                                    placeholder={'Postal'}
                                                    value={values?.postal}
                                                    onChange={handleChange} />
                                                {touched.postal && errors.postal && <div className='validatonError' >{errors.postal}</div>}
                                            </Col>
                                        </Row>
                                        <h3>What is your contact information? </h3>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Phone Number</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'phoneNumber'}
                                                    placeholder={'Phone Number'}
                                                    value={values?.phoneNumber}
                                                    onChange={handleChange} />
                                                {touched.phoneNumber && errors.phoneNumber && <div className='validatonError' >{errors.phoneNumber}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Email</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'email'}
                                                    placeholder={'Email'}
                                                    value={values?.email}
                                                    onChange={handleChange} />
                                                {touched.email && errors.email && <div className='validatonError' >{errors.email}</div>}
                                            </Col>
                                        </Row>

                                    </>}

                                    {/* {values?.deliveryMethod === 'PICKUP' && <PickupComponent setCheckoutRef={(ref) => { props.setCheckoutRef(ref) }} currentValue={values} handleDeliveryData={(data) => handleDeliveryData(data)} seller={props.wigCreatorInfo} option='PICKUP' />} */}
                                    {/* {values?.deliveryMethod === 'INSTALLATION' && props?.wigCreatorInfo?.services?.wigInstallation && <InstallationComponent currentValue={values} handleDeliveryData={(data) => handleDeliveryData(data)} option='INSTALLATION' cart={userCart} seller={props?.wigCreatorInfo} installationOptions={props?.wigCreatorInfo?.services?.wigInstallation} />} */}
                                </div>
                            </Col>
                            <Button disabled={!isValid} onClick={handleSubmit}  type="submit">Continue</Button>
                        </Form>


                    )}
                </Formik>}


            </Container>


        </div>
    )
}

export default DeliveryFormComponent;