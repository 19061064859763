import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import "./AdminComponent.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import SidebarComponent from "./SidebarComponent/SidebarComponent.js";

// import Home from "./pages/Home/Home.js";
// import Homepage from "./pages/Homepage/Homepage.js";
import OrdersComponent from "./pages/Orders/OrdersComponent.js";
// import Wigs from "./pages/Wigs/Wigs.js"
// import Shipping from "./pages/Shipping/Shipping.js";
import Profile from "./pages/Profile/Profile.js";
// import Payments from "./pages/Payments/Payments.js";
// import { Reports, ReportsOne, ReportsTwo } from "./pages/Reports/Reports.js";

import { Icon, Input } from "semantic-ui-react";
import { firestore, auth } from '../../services/firebase';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserId,updateUserInfo } from '../../redux/user/userSlice'


function ClientDashboardComponent() {
  var home;
  const [activeItem, setActiveItem] = useState(home);
  const handleItemClick = (e, { name }) => setActiveItem({ activeItem: name });
  const dispatch = useDispatch();
  
  const userId = useSelector((state) => state.User.userId)

  useEffect(() => {
  
    
 
  }, [])

  // useEffect(()=>{
  //   if(userId) { 
  //   firestore.collection('wigCreators/'+userId+'/businessInformation').doc('public').get((data)=>{
  //       console.log('mush',data)
  //       dispatch(updateAdminInfo(data))
  //     })}
  // },[userId])


  return (
    <Router>
      <div >
        {/* <Navbar variant="light" bg="light">
          <Navbar.Brand>Jasmin Wigs</Navbar.Brand>
          <Form inline>
            <Input icon placeholder="Search...">
              <input />
              <Icon name="search" />
            </Input>
       
          </Form>
        </Navbar> */}
        <Container>

          <Row>
            <div className="admin-box">
              <Col>
                <SidebarComponent />
              </Col>
            </div>
            <Col>
              <Switch>
                {/* <Route path="/homepage" exact component={Homepage} />
                <Route path="/reports" exact component={Reports} />
                <Route
                  path="/reports/reports1"
                  exact
                  component={ReportsOne}
                />
                <Route
                  path="/reports/reports2"
                  exact
                  component={ReportsTwo}
                />
                <Route path="/orders" exact render={(props) => <OrdersComponent view= "ORDERLIST" />} />
                <Route path="/products" exact component={Wigs} />
                <Route path="/shipping" exact component={Shipping} />
                <Route path="/policy" exact component={Policy} />
                <Route path="/payment" exact component={Payments} /> */}
                <Route path="/clientDashboard/profile" exact component={Profile} />
                <Route path="/clientDashboard/purchases" exact component={OrdersComponent} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </div>
    </Router>
  );
}

export default ClientDashboardComponent;