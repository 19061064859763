import React, { useState, useRef } from "react";
// import "./CustomHomepage.css";
// import Form from "react-bootstrap/Form";
// import Accordion from "react-bootstrap/Accordion";
// import Card from "react-bootstrap/Card";
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { Segment } from 'semantic-ui-react'
import { makeStyles } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Typography from '@mui/material/Typography';
import Select from "react-select";
import { firestore } from '../../../../services/firebase'
import { useSelector, useDispatch } from 'react-redux'

const PREVIEW = 'PREVIEW'
const POLICIES = 'POLICIES'
const Profile = () => {
    const userId = useSelector((state) => state.User.userId)

    var initialValues = {
        machineSew: false,
        handStiched: false
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const customHompageFormRef = useRef()

    const [view, setView] = useState(POLICIES)

    function uploadFile(id) {
        document.getElementById(id).click();
    }

    return (
        <div className="edit-box">
            <h2 className="title">Public Profile</h2>
            <Col>
                <Typography color="secondary">
                    {/* <Button onClick={() => { if (view === POLICIES) { setView(PREVIEW) } else { setView(POLICIES) } }}>preview</Button> */}
                    {view == POLICIES &&
                        <Formik
                            innerRef={customHompageFormRef}
                            initialValues={initialValues}

                            onSubmit={(values) => {
                                console.log('show da value', values)

                            }}

                            validateOnChange={(checking) => {
                                console.log('the checking', checking)
                            }}





                        >
                            {({ handleChange, handleSubmit, handleBlur, values, errors, setFieldValue }) => (
                                <div>

                                    <Form>


                                        <div>
                                            {/* <Col>
                                                <Button onClick={() => { handleSubmit() }}>save</Button>
                                            </Col> */}
                                            <Row >

                                                <Col sm="12">
                                                    {/* <h2>Profile Picture</h2> */}
                                                    <Col>
                                                        <FormLabel htmlFor='img' >Profile Picture: </FormLabel>
                                                        <input type="file" id="img" name="img" accept="image/*" />
                                                        {/* <FormControl
                                                                name={'businessName'}
                                                                checked={values.businessName}
                                                                onChange={handleChange} /> */}

                                                    </Col>
                                                    <hr />
                                                    <Col>
                                                        <FormLabel >First Name</FormLabel>
                                                        <FormControl
                                                            name={'firstName'}
                                                            checked={values.firstName}
                                                            onChange={handleChange} />
                                                        <FormLabel >Last Name</FormLabel>
                                                        <FormControl
                                                            name={'lastName'}
                                                            checked={values.lastName}
                                                            onChange={handleChange} />
                                                        <FormLabel >Last Name</FormLabel>
                                                        <FormControl
                                                            name={'lastName'}
                                                            checked={values.lastName}
                                                            onChange={handleChange} />

                                                    </Col>
                                                    <hr />
                                                    <Col>
                                                        <FormLabel >City</FormLabel>
                                                        <FormControl
                                                            name={'city'}
                                                            checked={values.city}
                                                            onChange={handleChange} />


                                                    </Col>
                                                    <hr />
                                                    {/* <h3>How It's made</h3>
                                                    <Row>
                                                        <Col>
                                                            <FormLabel >Business Name</FormLabel>
                                                            <FormControl
                                                                name={'businessName'}
                                                                checked={values.businessName}
                                                                onChange={handleChange} />

                                                        </Col>
                                                        <Col>
                                                            <FormLabel >About </FormLabel>
                                                            <FormControl
                                                                name={'aboutYou'}
                                                                as="textarea"
                                                                checked={values.aboutYou}
                                                                onChange={handleChange} />

                                                        </Col>
                                                    </Row>` */}


                                                    <h3>Social Media</h3>
                                                    <Row>
                                                        <Col>
                                                            <FormLabel >Instagram </FormLabel>
                                                            <FormControl

                                                                // as="textarea"
                                                                // type="checkbox"
                                                                name='instagramUrl'
                                                                checked={values.instagramUrl}
                                                                onChange={handleChange} />

                                                        </Col>
                                                        <Col>
                                                            <FormLabel >Facebook </FormLabel>
                                                            <FormControl

                                                                // as="textarea"
                                                                // type="checkbox"
                                                                name='facebookUrl'
                                                                checked={values.facebookUrl}
                                                                onChange={handleChange} />

                                                        </Col>
                                                        <Col>
                                                            <FormLabel >Youtube </FormLabel>
                                                            <FormControl

                                                                // as="textarea"
                                                                // type="checkbox"
                                                                name='youtubeUrl'
                                                                checked={values.youtubeUrl}
                                                                onChange={handleChange} />

                                                        </Col>

                                                    </Row>


                                                </Col>

                                            </Row>

                                        </div>



                                    </Form>

                                </div>
                            )}
                        </Formik>}
                    {/* {view == PREVIEW && <HomepagePreview />} */}

                </Typography>
            </Col>

        </div>

    );
}

export default Profile;
