import React, { useEffect, useState } from "react";
import "./WhyUsSection.css";
import { BrowserRouter as Router, Route, Link, Switch, useHistory } from "react-router-dom";
import { Image, Divider } from "semantic-ui-react";

import Button from "react-bootstrap/Button";

function WhyUsSection() {
    const history = useHistory();
    return (
        <div className="whyUs-container">
            <h1>Why  Yornel?</h1>
            <div className="list-container">
                <ul className="list-box">
                    <li className="item-list">
                        <h2>A community doing good</h2>
                        <p>
                            Yornel is a global online hair marketplace, where people come together to make,
                            sell, buy quality hair products. We’re also a community pushing for positive change for small businesses, and people.
                    </p>
                    </li>
                    <li className="item-list">
                        <h2>Support wig creators</h2>
                        <p>
                            There’s no Yornel warehouse – just people in your community making something they know you will love. We make the whole process easy, helping you connect directly with makers to find something extraordinary.
                    </p>
                    </li>
                    <li className="item-list ">
                        <h2>Peace of mind </h2>
                        <p>Your privacy is the highest priority of our dedicated team. And if you ever need assistance, we are always ready to step in for support. </p>
                    </li>
                </ul>
            </div>
            <h3>Have more questions? Well, we’ve got some answers.</h3>
            <div className="btn-container">
                <Button variant="outline-primary" onClick={() => {
                    history.push('/about')
                }} >Learn more</Button>

            </div>
        </div>
    );
}

export default WhyUsSection;