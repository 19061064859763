import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import firebase, { auth } from '../../services/firebase'


const SignUp = (props) => {
    const [errorMessage, setErrorMessage] = useState()

    //Todo format, also add uploaded image
    return (
        <>
            <Formik
                initialValues={{ email: '', password: '', username: '' }}
                onSubmit={(values) => {
                    
                    auth.createUserWithEmailAndPassword(values.email, values.password).then((userCredntial) => {
                        userCredntial.user.sendEmailVerification();
                            userCredntial.user.updateProfile({displayName:values.username}).then(()=>{
                               
                            }).catch(err=>{
                                console.log('err',err)
                            })
                            props.closeModal()
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    })


                }}
            >
                {({ errors, touched, handleChange, values }) => (

                    <Form>



                        <Col lg={6} sm={3}>
                            <h3 style={{ textAlign: 'center' }}>Sign Up</h3>
                            <FormLabel >Username</FormLabel>
                            <FormControl className={'p-3 m-3'} name={'username'}
                                type="text"
                                placeholder="Username"
                                value={values.username}
                                onChange={handleChange} />
                            <FormLabel >Email</FormLabel>
                            <FormControl className={'p-3 m-3'} name={'email'}
                                type="text"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange} />
                            <FormLabel >Password</FormLabel>
                            <FormControl className={'p-3 m-3'} name={'password'}
                                type="text"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange} />

                            {errorMessage && <p style={{color:'red'}}>{errorMessage}</p>}
                            <Button style={{ float: 'right' }} type="submit">Sign Up</Button>
                        </Col>
                    </Form>

                )}
            </Formik>
        </>
    )
}

export default SignUp;