import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../redux/user/userSlice';
import { firestore } from '../../services/firebase'
import firestoreService from '../../services/firestoreService'
import _ from 'lodash';
import CartItemComponent from '../Cart/CartItemComponent'
import WigDescriptionComponent from '../shared/WigDescriptionComponent';
import moment from 'moment'
import { FaStripe, FaCcVisa, FaCcMastercard, FaCcJcb, FaCcAmex } from 'react-icons/fa';
import { ReactComponent as Stripe } from '../../assets/stripe.svg'
import Popup from 'reactjs-popup';
import ClipLoader from "react-spinners/ClipLoader";

function ReviewComponent(props) {
    const userCart = useSelector((state) => state.User.cart)
    const userId = useSelector((state) => state.User.userId);
    const orderId = firestoreService.getNewOrderId();
    const stylistInfo = useSelector((state) => state.User.cartStylistInfo);
    const orderNotes = useSelector((state) => state.User.orderNotes)
    const [finalTotal, setFinalTotal] = useState(0);
    const [itemsTotal, setItemsTotal] = useState(0)
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();

    //sum up of delivery fees and cart total 
    useEffect(() => {
        var tempTotal = 0
        
        console.log('ReviewComponent', props);
        console.log('stylistInfo', stylistInfo);
        props.cart.map(items => { tempTotal = items.finalPrice + tempTotal });
        setItemsTotal(_.cloneDeep(tempTotal)) // setting the total of all items cloning deep incase the value is affected by refrence

        // if (props?.deliveryData?.deliveryMethodSelected === 'installation') {
        //     tempTotal = tempTotal + parseInt(props.wigCreatorInfo.services.minimumDeposit.amount);
        // } else if (props.deliveryData.deliveryMethodSelected === 'shipping' && props.wigCreatorInfo.shipping.shippingProfile.shippingCostMethod === 'fixedPrice') {
        //     tempTotal = tempTotal + parseInt(props.wigCreatorInfo.shipping.shippingProfile.fixedPrice);
        // }

        console.log('show me da money', tempTotal)
        setFinalTotal(tempTotal)

    }, [])

    // function remove(){
    // var tempItems =_.cloneDeep(userCart);
    // tempItems.splice(props.index,1)
    // dispatch(updateCart(tempItems));
    // firestore.collection('users/' + userId + '/cart').doc(props.items.cartId).update({displayInCart:false});


    // }

    //create a whole order obj to manage 
    function handleOrder() {
        console.log('what is userId in review',userId)

        const deliveryData = props.deliveryData
        const wigCreatorInfo = props.wigCreatorInfo
        const cart = props.cart;
        // const orderId = firestoreService.getNewOrderId()
        const modifiedLog = { userId: userId, timeStamp: moment.utc().unix(), action: 'ORDER_CREATION_INTENT_TO_PAY' }
        const orderDetails = {
            items: cart,
            orderId: orderId,
            wigCreatorId: cart[0].wigCreatorId, //wigCreatorInfo.businessInformation.businessId, //temp right now no bussiness account setup in place
            clientId: userId,
            clientEmail: email,
            clientPhoneNumber: phoneNumber,
            deliveryMethod: deliveryData,
            modifiedAt: moment.utc().unix(),
            createdAt: moment.utc().unix(),
            depositPaid: false,
            paymentStatus: 'UNPAID',
            orderStatus: 'WAITING_FOR_PAYMENT',
            uiOrderTotalAmount: finalTotal,
            // currentUrl:,
            discounts: 0,
            discountCode: null,
            promotion: 'NONE',
            notes: orderNotes,
            orderLog: [modifiedLog],
            orderFullfilled: false,
            appointment: {
                appointmentStatus: 'NONE',
                hasAppointment: false,
                appointmentType: 'INSTALL',
                startTime: null,
                approvedByStylist: false,
                approvedByClient: false,
                clientContacted: false,
                location: 'STYLIST',
                notes: '',
                modifiedAt: moment.utc().unix()
            }
        }

        console.log('chekcingf final oreder', orderDetails);
        console.log('wigcreator', wigCreatorInfo)
        firestoreService.createOrder(orderId, orderDetails).then(data => {
            console.log('we successfully created an order', data, orderId)
            firestoreService.payWithStripe(orderId).then(data => {
                const url = data.data;
                window.open(url, "_self")
            }).catch(err => {
                console.log('Err has occured', err)
                setLoading(false)

            })
        });

    }


    return (
        <>

            <Container>
                {/* <img src={props.item.images[0]} /> */}
                {/* <h2>Review Order</h2> */}
            </Container>
            <Container >
                <h1 className='mt-2' style={{ color: 'black' }}>Items</h1>
                <div className='formatServices'>


                    {userCart.map((item, index) => {
                        return (
                            <>
                                <CartItemComponent index={index} item={item} hideDelete={true} isLastIndex={userCart.length - 1 === index} />

                            </>
                        )
                    })}

                    {/* {props?.deliveryData?.deliveryMethodSelected === 'installation' && <>
                        <h5>Installation Services</h5>
                        <p  >
                            <Row>
                                <Col><p style={{ textAlign: 'start' }}> Service Name</p></Col>
                                <Col><p style={{ textAlign: 'end' }}> {props.deliveryData.shipping.selectedInstallation.label} </p></Col>
                            </Row>
                            <Row>
                                <Col><p style={{ textAlign: 'start' }}>Price</p></Col>
                                <Col><p style={{ textAlign: 'end' }}> ${props.deliveryData.shipping.selectedInstallation.price} </p></Col>
                            </Row>
                            <Row>
                                <Col><p style={{ textAlign: 'start' }}>Your Contact Number   </p></Col>
                                <Col><p style={{ textAlign: 'end' }}> {props.deliveryData.shipping.phoneNumber} </p></Col>
                            </Row>
                            <Row>
                                <Col><p style={{ textAlign: 'start' }}>Stylist   </p></Col>
                                <Col><p style={{ textAlign: 'end' }}> {props.wigCreatorInfo.businessInfo.businessName} </p></Col>
                            </Row>
                            <Row>
                                <Col><p style={{ textAlign: 'start' }}>Location   </p></Col>
                                <Col><p > {props.wigCreatorInfo.businessInfo.businessAddress.city},{props.wigCreatorInfo.businessInfo.businessAddress.state},{props.wigCreatorInfo.businessInfo.businessAddress.postalCode},{props.wigCreatorInfo.businessInfo.businessAddress.country} </p></Col>
                            </Row>
                           


                    Notice: A Yornel Staff Member  will reach out to set up an appointment<br />



                        </p>
                        <hr />
                    </>} */}

                </div>


                {props?.deliveryData?.deliveryMethodSelected === 'installation' && <>
                    <h1 className='mt-5' style={{ color: 'black' }}>Installation Services</h1>
                    <div className='formatPayment'>
                        {props?.deliveryData?.deliveryMethodSelected === 'installation' && <>

                            <p  >
                                <Row>
                                    <Col>
                                        <h5> Service Name</h5>
                                        <p> {props.deliveryData.shipping.selectedInstallation.label} </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h5>Price </h5>
                                        <p > ${props.deliveryData.shipping.selectedInstallation.price} </p>
                                    </Col>

                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h5>Your Contact Number   </h5>
                                        <p> {props.deliveryData.shipping.phoneNumber} </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h5>Stylist  </h5>
                                        <p> {props.wigCreatorInfo.businessInfo.businessName} </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h5>Location  </h5>
                                        <p>  {props.wigCreatorInfo.businessInfo.businessAddress.city+','}{props.wigCreatorInfo.businessInfo.businessAddress.state+','}{props.wigCreatorInfo.businessInfo.businessAddress.postalCode+','}{props.wigCreatorInfo.businessInfo.businessAddress.country+','} </p>
                                    </Col>

                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h5>Notice  </h5>
                                        <p>  A Yornel Staff Member  will reach out to set up an appointment </p>
                                    </Col>

                                </Row>



                            </p>
                            <hr />
                        </>}
                    </div>
                </>}

                {props?.deliveryData?.shipping?.deliveryMethod === 'PICKUP' || props?.deliveryData?.shipping?.deliveryMethod === 'SHIPPING'&&<h1 className='mt-5' style={{ color: 'black' }}>Delivery Method</h1>}
                {props?.deliveryData?.shipping?.deliveryMethod === 'PICKUP' && <>
                    <div className='formatPayment'>
                        {<>

                            <p  >
                                <Row>
                                    <Col>
                                        <h3> {props.deliveryData.shipping.deliveryMethod}</h3>
                                        {/* <p> {props.deliveryData.shipping.deliveryMethod} </p> */}
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col>
                                        <h5> Pickup Instructions:</h5>
                                        <p> {props?.wigCreatorInfo?.shipping?.wigPickUp?.wigPickUpInstructions} </p>
                                    </Col>
                                </Row>
                            </p>

                        </>}
                    </div>
                </>}

                {props?.deliveryData?.shipping?.deliveryMethod === 'SHIPPING' && <>
                    <div className='formatPayment'>
                        {<>


                            <Row>
                                <Col>
                                    <h3> Shipping To</h3>
                                    {/* <p> {props.deliveryData.shipping.deliveryMethod} </p> */}
                                </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col>
                                    <h5> Address:</h5>
                                    <p> {props?.deliveryData?.shipping?.address} </p>

                                    <h5> City:</h5>
                                    <p> {props?.deliveryData?.shipping?.city} </p>

                                    <h5> Country:</h5>
                                    <p> {props?.deliveryData?.shipping?.country} </p>

                                    <h5> Province:</h5>
                                    <p> {props?.deliveryData?.shipping?.province} </p>

                                    <h5> Postal/Zip Code:</h5>
                                    <p> {props?.deliveryData?.shipping?.postal} </p>
                                </Col>
                            </Row>


                        </>}
                    </div>
                </>}



                <h1 className='mt-5' style={{ color: 'black' }}>Payment</h1>
                <div className='formatPayment'>
                    <Row>
                        <Col>


                            {props.deliveryData?.shipping?.deliveryMethod === 'INSTALLATION' && <span>





                                {props.wigCreatorInfo.services.minimumDeposit.required && <span>
                                    <h5>Service Minimum Deposit:</h5>
                                    {<p> ${props.wigCreatorInfo.services.minimumDeposit.amount} </p>}
                                </span>}

                            </span>}

                            {props?.deliveryData?.shipping?.deliveryMethod === 'SHIPPING' &&
                                <span>

                                    <h5>Shipping</h5>
                                    <p>${props.wigCreatorInfo.shipping.shippingProfile.fixedPrice}</p>



                                </span>}

                            {props?.deliveryData?.shipping?.deliveryMethod === 'PICKUP' &&
                                <span>

                                    <h5>Shipping</h5>
                                    <p>Free</p>



                                </span>}
                                <hr />
                            <span>
                                <h5>Items Total:</h5>
                                     ${itemsTotal}
                            </span>

                            <hr />
                            <span>
                                <h5>Total:</h5>
                                <p> ${finalTotal}</p>
                            </span>
                        </Col>

                    </Row>
                    {/* <p style={{ textAlign: 'end' }}>
                        Service Deposit: ${25}<br />
                        Amount of money owed after service is compeleted
                        {props.deliveryData?.installation && ' Service Amount Owed: $' + parseInt(props.deliveryData.shipping.selectedInstallation.price) - 25} <br />
                    Shipping: Free <br />
                    Total: ${finalTotal}
                    </p> */}
                </div>
                <div >
                    <button disabled={loading} onClick={() => {
                       setLoading(true)
                       handleOrder();
                        

                    }} className='formatButton' >
                        Pay Now
                    </button>
                    <Col>
                        <div style={{ width: '20%', margin: '0 auto', marginBottom: '15px' }} >
                            <Stripe />
                        </div>
                      
                    </Col>

                </div>

            </Container>

            <Popup open={loading} closeOnDocumentClick={false} closeOnEscape={false} position="right center" modal>   
             <div className='loadingToStripe'>
                 <Col> 
                 <div className='load'> 
                     <ClipLoader color={'black'} size={35} loading={true} />
                 </div>
             
             Redirecting You To Stripe
             </Col>
                 </div>  
                 </Popup>
        </>
    )
}

export default ReviewComponent;