import "./HomePage.css";
import React, { useEffect, useState } from "react";
import { Input, Menu, Image, Button } from "semantic-ui-react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import proccesTrack from '../../assets/sewing-machine.png';
import topImg from "../../assets/christina-wocintechchat-com-RTJIXQNne68-unsplash.jpg"
import wigMaker from "../../assets/Wigs-For-African-American-Women.jpg";
import sewingMachinePng from '../../assets/sewing-machine.png';
import wigOnMannequin from '../../assets/wig2.png';
import organize from '../../assets/organizer.png';
import tracking from '../../assets/tracking.png';
import protection from '../../assets/protection.png';


import mainImage from "../../assets/womenDrinkingCoffe.jpeg";

import BusinessComponent from "../BusinessComponent";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import {Icon,Step } from "semantic-ui-react";



// const BUSINESSPAGE = "BUSINESSPAGE";
const BUSINESSLIST = "BUSINESSLIST";
function HomePage(){
const [component, setComponent] = useState();
const demoSteps = [
  {
    completed:true,
    key: 'shipping',
    icon: 'truck',
    title: 'Wig Maker Received Order',
    // description: 'Will begin in 2-4 days',
  },
  {
    key: 'billing',
    active: true,
    icon: 'cut',
    title: 'Wig is being processed',
  },
  { key: 'confirm', disabled: true, icon: 'truck', title: 'Wig has been shipped' },
]


    return (
      <BrowserRouter>
      <div className="home-box">
        <div className="home-header">
          <Container>
            <Row>
                <Col sm={5} >
                  {/* <h1>Yornel</h1> */}
                  <h2>
                    Sell Your Custom Wigs Online With A Peace of Mind <br />
                    
                  </h2>
                  <p>
                   Try Yornel Free! Enter Your Email Address And a Representative will in contact with you    
                  </p>
                  <Row> 

                    <Col sm={5}>
                    <Input size="huge" placeholder='Search...' />
                    <Button>Get In-Touch</Button>
                    </Col>
                    {/* <Col sm={5}>
                      <Button>Get In-Touch</Button>
                    </Col> */}
                  </Row>
                  
                  <p>
                   Yornel is a specialized marketplace for wig makers, We help get you new clients and keep the old, while managing to update your clients
                   every step along the way   <br /> to fill your schedule
                  </p>
                </Col>
                <Col>
                    <Image src={mainImage} />
                </Col>
            </Row>            
          </Container>
        </div>
        <div className="main-box">
          <Container className="text-box">
            <Row><h1>Grow Your Wig Bussiness</h1></Row>
            <Row>
              <Col>
                
                <Icon color="green" name="tag" size="large" />
                <h4>Sell Custom Made Wigs</h4>
                <p>No more losing customers to delayed responses. Yornel will gather all the details you need to begin the custom unit.While you have time to work on other things</p>
              </Col>
              <Col>
              <Icon color="green" name="users" size="large" />
                  <h4>Get more clients</h4>
                  <p>We Advertise your bussiness for you, when signed up you are automatically listed on our marketplace</p>
              </Col>
              <Col>
              <Icon color="green" name="shield" size="large" />
                  <h4>Wig seller protection</h4>
                  <p>Protect your bussiness from scammers, with our anti-fraud tools. Spend less time fighting each case while you focus on your bussiness </p>
              </Col>
              <Col>
              <Icon color="green" name="time" size="large" />
                  <h4>Realtime updates for all clients</h4>
                  <p>Clients will known at what stage their order is at. giving them a peace of mind.</p>
              </Col>
            </Row>
          </Container>
          <Container className="container-one">
          <Row>                
              <Col>
                <h1>Sell Wigs </h1>
                <p>
                  Sell ready-made or custom wigs. Yornel enables you to sell wigs that fits your bussiness giving you time back to re-invest into your bussiness. Yornel offers you a platform specialized to wig sellers, where considertions are taken for your bussiness.
                  You get your funds in your account as soon as the order has been placed.``
              </p>
              </Col>
              <Col xs={5}>
                <Image src={wigOnMannequin} />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
              {/* <Icon color="green" name="spy" size="large" /> */}
                  <Image src={protection} />
              </Col>
              <Col>
                <h1>Fraud & Disputes. </h1>
                <p>
                We know about unsavory characters who scam, we block or will notify you when a known scammer places an order.
                   If they filed a disput with any payment provider don't worry we will give you a print out of all the proof you need,
                   to ensure the payment provider honors the agreement, with one click of a button. No more wasting time on fraud, just focus on what you do best.
                </p>
              </Col>
            </Row>
            <Row >                
              <Col>
                <h1>Organize Your Orders. </h1>
                <p>
                    Current and New Clients can order a wig
                    24/7, we will notify you the time and service with real-time
                    updates. All your orders found in one location, where you can update and give realtime updates to all your clients. 
                    
              </p>
              </Col>
              <Col xs={5}>
                <Image src={organize} />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
              <Step.Group vertical size="massive" items={demoSteps} />
              </Col>
              <Col>
                <h1>Real-Time Updates for your clients</h1>
                <p>
                Clients will get a notifications and a real-time order tracking to be aware of their order status. With clients aware what the status of their order is, this will reduce cancellictions and chargeback
                </p>
              </Col>
            </Row>
            {/* <Row>                
              <Col>
                <h3>Discover and book professionals near you </h3>
                <p>
                  Finding a stylist for your hair now made easy. You don't need
                  to look anywhere else
              </p>
              </Col>
              <Col xs={5}>
                <Image src={wigMaker} />
              </Col>
            </Row> */}
          </Container>
          {/* <Container className="container-two">
            <Row>
              <h1>Choose your own path to success  </h1>
              <p>Yornel has tools designed for every stage of your business's growth<br/>
                  no matter how big your ambitions.</p>
            </Row>
          </Container> */}
          <Container className="radded-edge">
              <h1>Choose your own path to success  </h1>
              <p>Yornel has tools designed for every stage of your business's growth<br />
                no matter how big your ambitions.</p> 
              
          </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#00cba9" fill-opacity="0.2" d="M0,64L18.5,80C36.9,96,74,128,111,154.7C147.7,181,185,203,222,181.3C258.5,160,295,96,332,58.7C369.2,21,406,11,443,42.7C480,75,517,149,554,154.7C590.8,160,628,96,665,96C701.5,96,738,160,775,165.3C812.3,171,849,117,886,90.7C923.1,64,960,64,997,96C1033.8,128,1071,192,1108,213.3C1144.6,235,1182,213,1218,202.7C1255.4,192,1292,192,1329,181.3C1366.2,171,1403,149,1422,138.7L1440,128L1440,0L1421.5,0C1403.1,0,1366,0,1329,0C1292.3,0,1255,0,1218,0C1181.5,0,1145,0,1108,0C1070.8,0,1034,0,997,0C960,0,923,0,886,0C849.2,0,812,0,775,0C738.5,0,702,0,665,0C627.7,0,591,0,554,0C516.9,0,480,0,443,0C406.2,0,369,0,332,0C295.4,0,258,0,222,0C184.6,0,148,0,111,0C73.8,0,37,0,18,0L0,0Z"></path>

            </svg>

        </div>
        
        
        {/* {component === BUSINESSLIST && <BusinessListing />}   */}
        {/* <BusinessListing /> */}
      </div>
      </BrowserRouter>
    );
}
 
export default HomePage;