import firebase, { firestore, storage } from "./firebase.js";

import { useSelector, useDispatch } from 'react-redux';

const firestoreService = {
    getWigCreatorInfo(wigCreatorId) {
        return  firestore.collection("sellers").doc(wigCreatorId)
        // return firestore.collection("wigCreators/" + wigCreatorId + '/public')
    },
    getWig(widId) {
        return firestore.collection("wigs").doc(widId).get()
    },
    getNewCartId(userId) {
        return firestore.collection('users/' + userId + '/cart').doc()
    },
    getCart(userId) {
        return firestore.collection('users/' + userId + '/cart').where('displayInCart', '==', true).get()
    },
    getNewOrderId() {
        return firestore.collection("orders").doc().id;
    },
    createOrder(orderId, orderDetails) {
        return firestore.collection('orders').doc(orderId).set(orderDetails);
    },
    payWithStripe(orderId) {
        var stripePayment = firebase.functions().httpsCallable('stripePayment');
        return stripePayment({ orderId: orderId })
    },
    getSellers(){
        return firestore.collection('wigCreators').where('isListed','==',true).get();
    },
    getSellerInfo(sellerId,cb) {
        firestore.collection("sellers").doc(sellerId).onSnapshot(doc=>{
            if(doc.exists){
                cb(doc.data());
            }else{
                cb({})
            }
        })
        
    },
    updateUniqueVisit(userId){

    },
    getControlValues(cb){
        firestore.collection('clientAdminControl').doc('controlValues').onSnapshot(doc=>{
            if(doc.exists){
                cb(doc.data())
            }
        })
    },
    updateUser(fieldName,data,userId){
        firestore.collection('users').doc(userId).collection('private').doc('profile').update({[fieldName]:{...data}})
    },
    getUserInfo(userId,cb){
        firestore.collection('users').doc(userId).collection('private').doc('profile').onSnapshot(doc=>{
            if(doc.exists){
                cb(doc.data())
            }
        })

    }



}


export default firestoreService;