import React, { useEffect, useState, useRef, forwardRef, createRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../redux/user/userSlice';
import { firestore } from '../../services/firebase';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { Formik, Field, ErrorMessage, } from 'formik';
import { Form, Radio, Checkbox } from 'semantic-ui-react'
import _ from 'lodash';
import Select from "react-select";
import { updateDisplayHeaderAndFooter } from '../../redux/user/userSlice';
import firestoreService from "../../services/firestoreService";
import { AddressValidationSchema } from './CheckoutValidation';
//  import * as Yup from 'yup';
function PickupComponent(props) {
    const loggedIn = useSelector((state) => state.User.loggedIn)
    const userInfo = useSelector((state) => state.User.userInfo)
    const userId = useSelector((state) => state.User.userId)
    const pickUpRef = useRef();


    useEffect(() => {
        var tempUserInfo = _.cloneDeep(userInfo?.shippingAddress || {})
        delete tempUserInfo['deliveryMethod'];


        props.handleDeliveryData({ deliveryMethodSelected: 'pickup', shipping: { deliveryMethod: props.option, ...tempUserInfo } })


    }, [])

    useEffect(() => {
        console.log('pickupRef', pickUpRef)
        props.setCheckoutRef(pickUpRef)
    }, [pickUpRef?.current])


    return (
        <>
            {props.option === 'CONTACT' && <p>Wig artist will contact you</p>}
            {props.option === 'NOTAVAILABLE' && <p>Sorry Stylist is currently not offering any pickup options</p>}
            {props.option === 'PICKUP' && <>
                {props?.seller && <p><b>{props?.seller.shipping.wigPickUp.wigPickUpInstructions}</b></p>}

                {props?.seller && <Formik // if the user is not logged in, or has no shipping details we will collect the information from them

                    innerRef={pickUpRef}
                    initialValues={{ deliveryMethod: props.option, firstName: props?.currentValue?.firstName || '', lastName: props?.currentValue?.lastName || '', phoneNumber: props?.currentValue?.phoneNumber || '', email: props?.currentValue?.email || '' }}
                    onSubmit={((values) => {
                        props.handleDeliveryData({ deliveryMethodSelected: 'pickup', shipping: { deliveryMethod: props.option, ...values } })


                    })}
                >
                    {({ handleChange, handleBlur, handleSubmit, values, setFieldValue }) => (

                        <Form>
                            <Col>
                                <Row>
                                    <Col className={'mt-2'}>
                                        <FormLabel ><h6>First Name</h6></FormLabel>
                                        <FormControl
                                            name={'firstName'}
                                            placeholder={'First Name'}
                                            value={values.firstName}
                                            onChange={handleChange} />

                                    </Col>
                                    <Col className={'mt-2'}>
                                        <FormLabel ><h6>Last Name</h6></FormLabel>
                                        <FormControl

                                            name={'lastName'}
                                            placeholder={'Last Name'}
                                            value={values.lastName}
                                            onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'mt-2'}>
                                        <FormLabel ><h6>Phone Number</h6></FormLabel>
                                        <FormControl

                                            name={'phoneNumber'}
                                            placeholder={'Phone Number'}
                                            value={values.phoneNumber}
                                            onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'mt-2'}>
                                        <FormLabel ><h6>Email</h6></FormLabel>
                                        <FormControl

                                            name={'email'}
                                            placeholder={'Email'}
                                            value={values.email}
                                            onChange={handleChange} />
                                    </Col>
                                </Row>
                            </Col>
                        </Form>

                    )}

                </Formik>}
            </>
            }

        </>
    )
}

function InstallationComponent(props) {
    const [installationOptions, setInstallationOptions] = useState(props.installationOptions)
    const [phoneNumber, setPhoneNumber] = useState(props.currentValue.phoneNumber || '')
    const [businessInfo, setBusinessInfo] = useState()
    const [userAgreed, setUserAgreed] = useState(false)
    const userInfo = useSelector((state) => state.User.userInfo)
    const [updated, setUpdated] = useState(false)


    const [selectedInstallation, setSelectedInstallation] = useState({})
    useEffect(() => {
        var tempUserInfo = _.cloneDeep(userInfo?.shippingAddress || {});
        delete tempUserInfo['deliveryMethod'];
        props.handleDeliveryData({ deliveryMethodSelected: 'installation', shipping: { deliveryMethod: 'INSTALLATION', phoneNumber, userAgreed, selectedInstallation, ...tempUserInfo } });

        if (props?.installationOptions[0]) {
            setSelectedInstallation(props?.installationOptions[0])
        }

        if (userInfo?.shippingAddress?.userAgreed) {
            setUserAgreed(userInfo?.shippingAddress?.userAgreed)
        }


    }, [])

    useEffect(() => {
        // console.log('whap whap')
        var tempUserInfo = _.cloneDeep(userInfo?.shippingAddress || {});
        delete tempUserInfo['phoneNumber'];
        delete tempUserInfo['userAgreed'];
        delete tempUserInfo['selectedInstallation'];
        delete tempUserInfo['deliveryMethod'];


        if (updated) {
            props.handleDeliveryData({ deliveryMethodSelected: 'installation', shipping: { deliveryMethod: 'INSTALLATION', phoneNumber, userAgreed, selectedInstallation, ...tempUserInfo } })

        }
    }, [phoneNumber, userAgreed, selectedInstallation, updated])
    return (
        <>
            <h4>Installation Options</h4>
            {<Select
                options={installationOptions}
                value={selectedInstallation}
                formatOptionLabel={(option) => { return (<>{option.name}  ${option.price} </>) }}
                onChange={(value) => {
                    setUpdated(true)
                    setSelectedInstallation(value)
                }}
            />}

            {selectedInstallation && <p><b>{selectedInstallation.description}</b></p>}
            {/* <FormLabel ><h6>Contact Phone Number</h6></FormLabel> */}
            <FormControl
                name={'phoneNumber'}
                placeholder={'Phone Number'}
                value={phoneNumber}
                onChange={(event, value) => {
                    console.log('checkin phonenumber', value);
                    console.log('checkin event', event.target.value);
                    setPhoneNumber(event.target.value)
                    setUpdated(true)
                }} />
            <p>
                Installation Done By: {props.seller.businessInfo.businessName} <br />
                Minimum Deposit: {props.seller.services.minimumDeposit.amount || 0}$<br />
                {/* Rules:{props.seller.appointmentRules} <br /> */}
                Notice:
                Minimum Deposit is required of {props.seller.services.minimumDeposit.amount || 0}$. After your wig is purchased a Yornel Support Member will call you to book a time and date<br />
            </p>
            {/* need  checkbox */}
            <Checkbox label={{ children: 'I agree with terms' }} checked={userAgreed} onChange={(event, value) => {
                setUserAgreed(value.checked)
                setUpdated(true)

            }} />
        </>
    )
}


function DeliveryComponent(props) {
    const userCart = useSelector((state) => state.User.cart)
    const userId = useSelector((state) => state.User.userId)
    const userInfo = useSelector((state) => state.User.userInfo)
    const loggedIn = useSelector((state) => state.User.loggedIn)
    const [shipping, setShipping] = useState({ deliveryMethod: 'SHIPPING' });
    const [deliveryData, setDeliveryData] = useState();
    const [addressData,setAddressData] = useState();

    const dispatch = useDispatch();
    const deliveryRef = useRef();

    useEffect(() => {

        dispatch(updateDisplayHeaderAndFooter({ header: false, footer: false }));
        // if cart is empty check if cart has data via firebase
        console.log('whap whap', deliveryRef.current)
        console.log('tell em shit', deliveryData?.shipping?.deliveryMethod)
        if (deliveryData?.shipping?.deliveryMethod === 'SHIPPING' && deliveryRef.current) {
            props.setCheckoutRef(deliveryRef)
            console.log('here 625', deliveryRef)

        }

    }, [deliveryRef?.current != null])

    // useEffect(() => {

    //     // if cart is empty check if cart has data via firebase
    //     console.log('tell me 2 chk', deliveryRef.current,deliveryData?.shipping)
      
    //         props.setCheckoutRef(deliveryRef)
         

    // }, [deliveryRef?.current])

    // useEffect(() => {

    //     // if cart is empty check if cart has data via firebase
    //     console.log('tell me 2 chk', deliveryRef.current,deliveryData?.shipping)
      
    //         props.setCheckoutRef(deliveryRef)
         

    // }, [])



    // useEffect(() => {
    //     var tempWigCreatorInfo = {}
    //     if (props.wigCreatorId) {
    //         firestoreService.getWigCreatorInfo(props.wigCreatorId).get().then(data => {
    //             data.docs.map(items => {
    //                 tempWigCreatorInfo[items.id] = items.data()
    //             })
    //             console.log('da wig', tempWigCreatorInfo)
    //             setWigCreatorInfo(tempWigCreatorInfo);
    //         })
    //     }
    // }, [props.wigCreatorId])

    useEffect(() => {
        var tempUserInfo = _.cloneDeep(userInfo?.shippingAddress || {});
        delete tempUserInfo['deliveryMethod'];
        if (props.deliveryData?.shipping) {
            handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: props.deliveryData.shipping })
        } else {
            // if (loggedIn) {// if the user is logged in then we will set old info if not will not set 
            handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: { deliveryMethod: 'SHIPPING', ...tempUserInfo } })
            // } else {
            //     handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: { deliveryMethod: 'SHIPPING' } })
            // }
        }
    }, [])

    function handleDeliveryData(data) {
        props.handleDeliveryData(data)
        setDeliveryData(data)
    }

    // function remove(){
    // var tempItems =_.cloneDeep(userCart);
    // tempItems.splice(props.index,1)
    // dispatch(updateCart(tempItems));
    // firestore.collection('users/' + userId + '/cart').doc(props.items.cartId).update({displayInCart:false});


    // }


    return (
        <>

            <Container>
                {/* <img src={props.item.images[0]} /> */}
                {/* <h2>Delivery Options</h2> */}
            </Container>
            <Container>
                {deliveryData?.shipping && <Formik
                    innerRef={deliveryRef}
                    isInitialValid = {AddressValidationSchema.isValidSync(deliveryData.shipping)}
                    initialValues={deliveryData.shipping}
                    validationSchema={AddressValidationSchema}
                    onSubmit={((values) => {
                        console.log('Delivery Shipping', values)
                        setAddressData(values)
                        if (values.deliveryMethod === 'shipping') {
                            firestoreService.updateUser('shippingAddress', values, userId);// updates the user with their shipping values
                        }
                        props.setCheckoutRef(deliveryRef)
                        props.nextTask(values, 'delivery')
                        handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: values })


                    })}

                >
                    {({ handleChange, handleBlur, handleSubmit, values, touched, errors ,setFieldValue }) => (

                        <Form>
                            <Col>
                                <div className='formatCheckout'>
                                    <h2 style={{ textAlign: 'center' }} > Delivery Method </h2>
                                    <Row>

                                        <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Shipping'
                                                name='deliveryMethod'
                                                value='SHIPPING'
                                                checked={values?.deliveryMethod === 'SHIPPING'}
                                                onChange={(event, data) => {
                                                    console.log('as the mind follows deepr', values)
                                                    setFieldValue('deliveryMethod', data.value)
                                                    var localData = _.cloneDeep(values)`1   `
                                                    delete localData['deliveryMethod']; // removed old delivery method selected so it does not overwrite the new one



                                                    handleDeliveryData({ deliveryMethodSelected: 'shipping', shipping: { deliveryMethod: 'SHIPPING', ...localData } }) // sets method to shipping if selected

                                                }}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Pickup'
                                                name='deliveryMethod'
                                                value='PICKUP'
                                                checked={values?.deliveryMethod === 'PICKUP'}
                                                onChange={(event, data) => { setFieldValue('deliveryMethod', data.value) }}
                                            />
                                        </Form.Field>
                                        {/* {props.wigCreatorInfo?.services?.acceptingAppointments && props.wigCreatorInfo?.services?.wigInstallation.length > 0 && <Form.Field>
                                            <Radio
                                                className="mr-4"
                                                label='Installation'
                                                name='deliveryMethod'
                                                value='INSTALLATION'
                                                checked={values?.deliveryMethod === 'INSTALLATION'}
                                                onChange={(event, data) => { setFieldValue('deliveryMethod', data.value) }}
                                            />
                                        </Form.Field>} */}
                                    </Row>
                                    <hr />
                                    {/* Shipping data */}
                                    {values.deliveryMethod === 'SHIPPING' && <>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>First Name</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'firstName'}
                                                    placeholder={'First Name'}
                                                    value={values.firstName}
                                                    onChange={handleChange} />
                                                {touched.firstName && errors.firstName && <div className='validatonError' >{errors.firstName}</div>}

                                            </Col>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Last Name</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'lastName'}
                                                    placeholder={'Last Name'}
                                                    value={values.lastName}
                                                    onChange={handleChange} />
                                                {touched.lastName && errors.lastName && <div className='validatonError' >{errors.lastName}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Address</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'address'}
                                                    placeholder={'Address'}
                                                    value={values.address}
                                                    onChange={handleChange} />
                                                {touched.address && errors.address && <div className='validatonError' >{errors.address}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Apartment</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'addressTwo'}
                                                    placeholder={'Apartment or Suite Number'}
                                                    value={values.addressTwo}
                                                    onChange={handleChange} />
                                                {touched.addressTwo && errors.addressTwo && <div className='validatonError' >{errors.addressTwo}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>City</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'city'}
                                                    placeholder={'City'}
                                                    value={values.city}
                                                    onChange={handleChange} />
                                                {touched.city && errors.city && <div className='validatonError' >{errors.city}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Country</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'country'}
                                                    placeholder={'Country'}
                                                    value={values.country}
                                                    onChange={handleChange} />
                                                {touched.country && errors.country && <div className='validatonError' >{errors.country}</div>}
                                            </Col>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Province</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'province'}
                                                    placeholder={'Province'}
                                                    value={values.province}
                                                    onChange={handleChange} />
                                                {touched.province && errors.province && <div className='validatonError' >{errors.province}</div>}
                                            </Col>
                                            <Col lg={4} sm={12} className={'mt-2'}>
                                                <FormLabel ><h6>Postal/Zip Code</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'postal'}
                                                    placeholder={'Postal'}
                                                    value={values.postal}
                                                    onChange={handleChange} />
                                                {touched.postal && errors.postal && <div className='validatonError' >{errors.postal}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Phone Number</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'phoneNumber'}
                                                    placeholder={'Phone Number'}
                                                    value={values.phoneNumber}
                                                    onChange={handleChange} />
                                                {touched.phoneNumber && errors.phoneNumber && <div className='validatonError' >{errors.phoneNumber}</div>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'mt-2'}>
                                                <FormLabel ><h6>Email</h6></FormLabel>
                                                <FormControl
                                                    onBlur={handleBlur}
                                                    name={'email'}
                                                    placeholder={'Email'}
                                                    value={values.email}
                                                    onChange={handleChange} />
                                                {touched.email && errors.email && <div className='validatonError' >{errors.email}</div>}
                                            </Col>
                                        </Row>

                                    </>}

                                    {values.deliveryMethod === 'PICKUP' && <PickupComponent setCheckoutRef={(ref) => { props.setCheckoutRef(ref) }} currentValue={values} handleDeliveryData={(data) => handleDeliveryData(data)} seller={props.wigCreatorInfo} option='PICKUP' />}
                                    {/* {values.deliveryMethod === 'INSTALLATION' && props?.wigCreatorInfo?.services?.wigInstallation && <InstallationComponent currentValue={values} handleDeliveryData={(data) => handleDeliveryData(data)} option='INSTALLATION' cart={userCart} seller={props?.wigCreatorInfo} installationOptions={props?.wigCreatorInfo?.services?.wigInstallation} />} */}
                                </div>
                            </Col>

                        </Form>


                    )}
                </Formik>}


            </Container>


        </>
    )
}

export default DeliveryComponent;