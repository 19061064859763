import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from 'react-redux';
import firestoreService from '../../services/firestoreService'
import _ from 'lodash';
import { ReactComponent as Stripe } from '../../assets/stripe.svg'
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link, useHistory } from "react-router-dom";
import { updateDisplayHeaderAndFooter } from '../../redux/user/userSlice';
import DeliveryFormComponent from "./topSection/DeliveryFormComponent";
import StripePaymentComponent from "./topSection/StripePaymentComponent";


import DeliveryAddressDetailsComponent from "./bottomSection/DeliveryAddressDetailsComponent";
import PaymentComponent from './bottomSection/PaymentComponent'
import OrderSummaryComponent from "./OrderSummaryComponent";

import Popup from 'reactjs-popup';
import ClipLoader from "react-spinners/ClipLoader";
function FinalPaymentComponent(props) {
    const ADDRESS_STEP='ADDRESS_STEP'
    const PAYMENT_STEP='PAYMENT_STEP'
    const userCart = useSelector((state) => state.User.cart);
    const dispatch = useDispatch();
    const deliveryRef = useRef();
    const [currentStep, setCurrentStep] = useState(ADDRESS_STEP)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(updateDisplayHeaderAndFooter({ header: false, footer: false }));
    }, [])

    function updateStatus(step){
        setCurrentStep(step)
    }


    return (
        <>
            <div className='checkoutContainer'>
                <div className="checkoutHeader">
                    <div className="top-header">
                        <Navbar
                            collapseOnSelect
                            variant="light"
                            expand="md"
                            // bg="light"
                            sticky="top"
                        >
                            <Navbar.Brand>
                                <Row>
                                    {/* <Image as={Link} to="/home" src={logoImg} size="tiny" /> */}
                                    <Link to="/home" >
                                        <h2>Yornel</h2>
                                    </Link>

                                </Row>
                            </Navbar.Brand>
                        </Navbar>
                    </div>
                </div>
                <div className='checkoutMainSection'>
                    {/* <Row> <Col>Delivery</Col> <Col>Pickup</Col></Row> */}
                    {currentStep===ADDRESS_STEP&&<DeliveryFormComponent updateStatus={()=>{updateStatus(PAYMENT_STEP)}} />}
                    {currentStep===PAYMENT_STEP&&<StripePaymentComponent updateStatus={()=>{updateStatus(PAYMENT_STEP)}} />}
                    <DeliveryAddressDetailsComponent />
                    <PaymentComponent />
                </div>
                <div className='checkoutItemSection'>
                    <OrderSummaryComponent cartItems={userCart} />
                </div>
            </div>
            <Popup open={loading} closeOnDocumentClick={false} closeOnEscape={false} position="right center" modal>   
             <div className='loadingToStripe'>
                 <Col> 
                 <div className='load'> 
                     <ClipLoader color={'black'} size={35} loading={true} />
                 </div>
             
             Redirecting You To Stripe
             </Col>
                 </div>  
                 </Popup>
        </>
    )

}

export default FinalPaymentComponent;