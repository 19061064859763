import React from 'react';
import { Component, useState, useRef, useEffect } from 'react';
import { Button, Dropdown, Header, Table } from 'semantic-ui-react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import firestoreService from '../../../../services/firestoreService';
import moment from 'moment';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";


import "./OrderDetails.css";

const ACCEPTED = "ACCEPTED";
const REJECTED = "REJECTED";
const ORDERPLACE = "ORDERPLACE";
const INPROCESS = "INPROCESS";
const NOTPROCESS = "NOTPROCESS";
const ZERO = "ZERO";
const NOTSHIPPED = "NOTSHIPPED";
const SHIPPED = "SHIPPED";
const FINISHED = "FINISHED";

const ORDER_APPROVED = 'ORDER_APPROVED'
const ORDER_DECLINED = 'ORDER_DECLINED'
const ORDER_COMPLETED = 'ORDER_COMPLETED'
const ORDER_IN_PROCESS = 'ORDER_IN_PROCESS'

const InvoiceDetails = (props) => {
  let history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [showAccept, setAccept] = useState(false);
  const [showReject, setReject] = useState(false);
  // const [showProcess, setShowProcess] = useState(false);
  const [confShow, setConfirmShow] = useState(false);
  const [confWigStartShow, setConfirmWigStartShow] = useState(false);
  const [showShipping, setShipping] = useState(false);

  const [orderShow, setOrderShow] = useState(ORDERPLACE);//display Accept/Reject
  const [orderInProcess, setInProcess] = useState(ZERO);//wig in process
  const [placeShip, setPlaceShip] = useState(ZERO);//wig in process
  // const [customShow, setCustomShow] = useState(ZERO);//
  const [orderFinished, setFinished] = useState(ZERO);

  const [order, setOrder] = useState()
  const [showItemDetails, setShowItemDetails] = useState();
  const [orderStatus, setOrderStatus] = useState()



  const handleCloseAccept = () => setAccept(false);
  const handleShowAccept = () => setAccept(true);

  const handleCloseReject = () => setReject(false);
  const handleShowReject = () => setReject(true);

  const handleCloseShipping = () => setShipping(false);
  const handleShowShipping = () => setShipping(true);

  const confirmClose = () => setConfirmShow(false);
  const confirmShow = () => setConfirmShow(true);

  const confirmWigStartClose = () => setConfirmWigStartShow(false);
  const confirmWigStartShow = () => setConfirmWigStartShow(true);

  const handleCloseItemDetails = (index) => {
    var temp = showItemDetails;
    temp[index] = false
    setShowItemDetails([...temp]);
  };

  let acceptedDate = moment().format("D MMMM YYYY");
  let rejectedDate = moment().format("D MMMM YYYY");
  let startDate = moment().format("D MMMM YYYY");
  let shippedDate = moment().format("D MMMM YYYY");
  let finishedDate = moment().format("D MMMM YYYY");

  const [deliveryName, setDeliveryName] = useState('');
  const [deliveryTrack, setDeliveryTrack] = useState('');

  function valueUpdated(obj) {

    return (
      obj.valueUpdated ? <p style={{ color: 'orange' }} >(Client Changed)</p> : ''
    )
  }

  let id = "14";

  useEffect(() => {
    var modals = []


    if (props.location.state && props.location.state.order) {
      setOrder(props.location.state.order)
      setOrderStatus(props.location.state.order.orderStatus)
      console.log('voice cracks', props.location.state.order);
      props.location.state.order.items.map((data, index) => {
        modals.push(false)
      })
      setShowItemDetails(modals)
    } else {
      history.push("/dashboard/orders/list");
    }

  }, [])

  // console.log(OrderListData,"list");

  const target = useRef(null);
  return (<>

    {order && <div>
      
      <Link to={{ pathname: `/dashboard/orders/list` }}   >
        
        <Button color="olive" >
          Back to list
             </Button>
      </Link>
      
      <Row>
        <Col >
          <h1>Order Items</h1>
          <div>
            <h5>Items({order.items.length}) </h5> {order.orderId}
          </div>




          {/* Items */}
          <div class="p-3 mb-5 " >
            {order.items.map((item, index) => {

              return (
                <>
                  <Row>
                    <img
                      className="itemImage"
                      src={item.images[0].originalUrl}
                    />

                    <Col>
                      <div> <b>{item.name}</b></div>
                      <div><Button onClick={() => {
                        var temp = showItemDetails;
                        temp[index] = true
                        setShowItemDetails([...temp])
                      }} >Show Wig Details</Button> </div>

                    </Col>
                    <Col>
                      <div>Quantity:1</div>
                    </Col>
                    <Col>
                      <div style={{ float: 'right' }}>
                        ${item.price}
                      </div>
                    </Col>

                  </Row>

                  <>

                  </>

                  <Modal
                    show={showItemDetails[index]}
                    onHide={() => handleCloseItemDetails(index)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title >
                        Wig Details
                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table bordered hover size="sm">
                        <tbody>
                          {item.hairOrigin && <tr>
                            <td title="Hair Origin">Hair Origin</td>
                            <td>{item.hairOrigin}{valueUpdated(item.hairOrigin)}</td>
                          </tr>}
                          <tr>
                            <td title="Length">Length</td>
                            <td>{item.length.label}{valueUpdated(item.length)}</td>
                          </tr>
                          <tr>
                            <td title="Texture">Texture</td>
                            <td>{item.texture.label}{valueUpdated(item.texture)}</td>
                          </tr>
                          <tr>
                            <td title="Density">Baby hairs</td>
                            <td>{item.babyHairs.label}{valueUpdated(item.babyHairs)}</td>
                          </tr>
                          <tr>
                            <td title="Cap Color">Cap Color</td>
                            <td>{item.capColor.label}{valueUpdated(item.capColor)}</td>
                          </tr>
                          <tr>
                            <td title="Cap Color">Color</td>
                            <td>{item.color.label}{valueUpdated(item.color)}</td>
                          </tr>
                          <tr>
                            <td title="Cap Construction">Cap Construction</td>
                            <td>{item.capConstruction.label}{valueUpdated(item.capConstruction)}</td>
                          </tr>
                          <tr>
                            <td title="Circumference">Circumference</td>
                            <td>{item.circumference.label}{valueUpdated(item.circumference)}</td>
                          </tr>
                          <tr>
                            <td title="Density">Density</td>
                            <td>{item.density.label}{valueUpdated(item.density)}</td>
                          </tr>
                          <tr>
                            <td title="Elastic Bands">Elastic Band</td>
                            <td>{item.elasticBand.label}{valueUpdated(item.elasticBand)}</td>
                          </tr>
                          <tr>
                            <td title="Elastic Bands and Combs">Elastic Bands and Combs</td>
                            <td>{item.elasticBandsAndCombs}{valueUpdated(item.elasticBandsAndCombs)}</td>
                          </tr>
                          <tr>
                            <td title="Extention Length 1">Extention Length 1</td>
                            <td>{item.extentionLength1.label}{valueUpdated(item.extentionLength1)}</td>
                          </tr>
                          <tr>
                            <td title="Extention Length 2">Extention Length 2</td>
                            <td>{item.extentionLength2.label}{valueUpdated(item.extentionLength2)}</td>
                          </tr>
                          <tr>
                            <td title="Extention Length 3">Extention Length 3</td>
                            <td>{item.extentionLength3.label}{valueUpdated(item.extentionLength3)}</td>
                          </tr>
                          <tr>
                            <td title="Glueless">Glueless</td>
                            <td>{item.glueless.label}{valueUpdated(item.glueless)}</td>
                          </tr>
                          {/* <tr>
                            <td title="Gluess">Gluess</td>
                            <td>{item.gluess}</td>
                          </tr> */}
                          <tr>
                            <td title="Wig Combs">Wig Combs</td>
                            <td>{item.wigCombs.label}{valueUpdated(item.wigCombs)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleCloseItemDetails(index)}>
                        Close
                </Button>

                    </Modal.Footer>
                  </Modal>

                  
                </>
              )
            })}

            <hr />

            {/* update order status */}
            {/* {orderStatus === 'ORDER_PAID_PENDING_WIG_CREATOR_APPROVAL' && <div style={{ float: 'right' }}>
              <Button onClick={() => {
                firestoreService.updateOrderStatus(order.wigCreatorId, order.orderId, ORDER_APPROVED);
                setOrderStatus(ORDER_APPROVED)
              }} >Accept Order</Button>
              <Button onClick={() => {
                firestoreService.updateOrderStatus(order.wigCreatorId, order.orderId, ORDER_DECLINED)
                setOrderStatus(ORDER_DECLINED)
              }}>Decline Order</Button>
            </div>}

            {orderStatus === ORDER_APPROVED && <div style={{ float: 'right' }}>
              <Button onClick={() => {
                firestoreService.updateOrderStatus(order.wigCreatorId, order.orderId, ORDER_IN_PROCESS)
                setOrderStatus(ORDER_IN_PROCESS)
              }} >Order In Process</Button>

            </div>}

            {orderStatus === ORDER_IN_PROCESS && <div style={{ float: 'right' }}>
              <Button onClick={() => {
                firestoreService.updateOrderStatus(order.wigCreatorId, order.orderId, ORDER_COMPLETED)
                setOrderStatus(ORDER_COMPLETED)
              }} >Order Completed</Button>

            </div>} */}

            {/* {orderStatus === ORDER_COMPLETED && <div style={{ float: 'right' }}>
              <Button onClick={() => {
                firestoreService.updateOrderStatus(order.wigCreatorId, order.orderId, ORDER_IN_PROCESS)
                setOrderStatus(ORDER_IN_PROCESS)
              }} >Order Completed</Button>

            </div>} */}



          </div>

          {order.deliveryMethod.deliveryMethodSelected === 'installation' && <>
            {/* services */}
            <>
              <div class="p-3 mb-5 ">
                <h1>Services</h1>
                {/* Installation Accounting */}
                <div >
                  <h3>Installation</h3>
                  <Row>
                    {/* Subtotal */}
                    <Col md={5}>
                      Closure Wig Install - Ready-made
              </Col>
                    <Col>

                    </Col>
                    <Col>
                      <div style={{ float: 'right' }}>
                        ${90}
                      </div>
                    </Col>
                  </Row>

                  {/* Deposit Paid */}
                  <Row>
                    <Col md={5}>
                      Minimum Deposit
              </Col>
                    <Col>
                      <b>PAID</b>
                    </Col>
                    <Col>
                      <div style={{ float: 'right' }}>
                        -${25}
                      </div>
                    </Col>
                  </Row>


                  <hr />

                  {/* Amount Owed */}
                  <Row>
                    <Col md={5}>
                      <b> Amount Owed</b>
                    </Col>
                    <Col>
                      <b> Balance </b>
                    </Col>
                    <Col>
                      <div style={{ float: 'right' }}>
                        <b> ${65} </b>
                      </div>
                    </Col>
                  </Row>
                </div>


              </div>
            </>

            {/* Appointment Section */}
            <div class="p-3 mb-5 ">
              <h1>Appointment</h1>
              {/* Appointment */}
              <div class='mb-4'>


                {/* Appointments */}
                <Row>
                  <Col md={5}>
                    <b> Date</b>
                  </Col>
                  <Col>
                    {/* <b>May 2 2023   </b> */}
                  </Col>
                  <Col>
                    <div style={{ float: 'right' }}>
                      <b>May 2 2023   </b>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <b> Start Time</b>
                  </Col>
                  <Col>
                    {/* <b>May 2 2023   </b> */}
                  </Col>
                  <Col>
                    <div style={{ float: 'right' }}>
                      <b>3:00pm  </b>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr />
              <div style={{ float: 'right' }}>
                <Button>Accept Appointment</Button>
                <Button>Reschedule/Cancel Appointment</Button>
              </div>

            </div>

          </>}

          {/* Payment Section */}
          <div class="p-3 mb-5 ">
            <h1>Payment</h1>

            <Row>
              {/* Subtotal */}
              <Col>
                Subtotal
              </Col>
              <Col>
                {order.items.length} item(s)
              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  ${order?.payment?.subTotalAmount}
                </div>
              </Col>


            </Row>


            {/* services */}
            {order?.deposit?.required && <Row>
              <Col>
                Services
              </Col>
              <Col>
                Deposit
              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  ${25}
                </div>
              </Col>
            </Row>}

            {/* shipping */}
            {<Row>
              <Col>
                Shipping
              </Col>
              <Col>
                Standard
              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  ${order.deliveryMethod.deliveryMethodSelected === 'shipping' ? 25 : 0}
                </div>
              </Col>
            </Row>}

            {/* fee */}
            <Row>
              <Col>
                Fee
              </Col>
              <Col>
                Yornel
              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  -${order?.payment?.yornelFee}
                </div>
              </Col>
            </Row>
            <hr />

            {/* Total */}
            <Row>
              <Col>
                <b> Total</b>
              </Col>
              <Col>

              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  <b>  ${order?.payment?.orderTotal}</b>
                </div>
              </Col>
            </Row>
            <hr/>
            {/* Amount Paid */}
            <Row>
              <Col>
                <b> Paid By Client</b>
              </Col>
              <Col>

              </Col>
              <Col>
                <div style={{ float: 'right' }}>
                  <b>  ${order?.payment?.amountPaid}</b>
                </div>
              </Col>
            </Row>
          </div>
          

        </Col>





        {/* Right side */}
        <Col >
          {/* Notes */}
          <div class="p-3 mb-5 " >
            <h5>Order Status</h5>
            <p>{orderStatus}</p>
          </div>
          {/* Notes */}
          <div class="p-3 mb-5 " >
            <h5>Notes</h5>
            <p>{order?.notes}</p>
          </div>
          {/* Customer */}
          <div class="p-3 mb-1 ">
            <h5>Customer</h5>
            <h6>Guest</h6>
            <h6>{order.firstName} {order.lastName}</h6>
          </div>
          {/* Contact Information */}
          <div class="p-3 mb-1 ">
            <h5>Contact Information</h5>
            <h6>test@test.com</h6>
          </div>

          {/* Contact Information */}
          <div class="p-3 mb-1 ">
            <h5>Shipping Address</h5>
            {order.deliveryMethod.deliveryMethodSelected === 'shipping' && <p>

              {order.deliveryMethod.shipping.address}<br />
              {/* {order.deliveryMethod.shipping.addressTwo}<br /> */}
              {order.deliveryMethod.shipping.city}<br />
              {order.deliveryMethod.shipping.province}<br />
              {order.deliveryMethod.shipping.country}<br />
              {order.deliveryMethod.shipping.postal}
            </p>}
            {order.deliveryMethod.deliveryMethodSelected === 'pickup' && <p>
              Client Will Pickup Order
              </p>}
          </div>
        </Col>
      </Row>
    </div>
    }


  </>

    //           <div className="invoice-box">
    //              <Link to={{pathname:`/dashboard/orders/list`}}   >
    //             <Button color="olive" >
    //               Back to list
    //             </Button>
    //             </Link>
    //             <Table celled>
    //               <Table.Header>
    //                 <Table.Row>
    //                   <Table.HeaderCell>Customer Details</Table.HeaderCell>
    //                   <Table.HeaderCell>Order Summary</Table.HeaderCell>
    //                   <Table.HeaderCell>Photo Uploaded</Table.HeaderCell>
    //                   <Table.HeaderCell>Actions</Table.HeaderCell>
    //                 </Table.Row>
    //               </Table.Header>

    //               <Table.Body>
    //                 <Table.Row>
    //                   {order&&<>
    //                         <Table.Cell >
    //                           <Table basic="very" celled collapsing>
    //                             <Table.Body>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Customer Name:
    //                                       <Header.Subheader>
    //                                         {order.firstName}{" "}
    //                                         {order.lastName}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 {order.deliveryMethodSelected === 'shipping'&&<Table.Cell>
    //                                   <Header as="h4" image>
    //                                     <Header.Content>
    //                                       Shipping Address
    //                                       <Header.Subheader>
    //                                         <ul>
    //                                           <li>{order.deliveryMethod.shipping.address}</li>
    //                                           <li>
    //                                             {order.deliveryMethod.shipping.city},{" "}
    //                                             {order.deliveryMethod.shipping.province},{" "}
    //                                             {order.deliveryMethod.shipping.postal}
    //                                           </li>
    //                                           <li>
    //                                             {order.deliveryMethod.shipping.country}
    //                                           </li>
    //                                         </ul>
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>}
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4" image>
    //                                     <Header.Content>
    //                                       Email:
    //                                       <Header.Subheader>
    //                                         {order.clientEmail}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Payment Method:
    //                                       <Header.Subheader>
    //                                         {/* {filteredList.payment} */}
    //                                         card
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Order Placed
    //                                       <Header.Subheader>
    //                                         {moment.unix(order.createdAt).format('LLLL')}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Order ID:
    //                                       <Header.Subheader>
    //                                         {order.orderId}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Agreed To Policy:
    //                                       <Header.Subheader>
    //                                         {/* {order.policyAgreement} */}
    //                                         true
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                             </Table.Body>
    //                           </Table>
    //                         </Table.Cell>
    //                         <Table.Cell>
    //                           <Table basic="very" celled collapsing>
    //                             <Table.Body>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Mesurements:
    //                                       <Header.Subheader>
    //                                         {/* <ul>
    //                                           <li>
    //                                             Circumference:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .circumference
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                           <li>
    //                                             Around Forehead:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .aroundForehead
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                           <li>
    //                                             Front To Nape:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .frontToNape
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                           <li>
    //                                             Nape Of Neck:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .napeOfNeck
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                           <li>
    //                                             Over The Top:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .overTheTop
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                           <li>
    //                                             Round The Back:{" "}
    //                                             {
    //                                               order.mesurements
    //                                                 .roundTheBack
    //                                             }{" "}
    //                                             in
    //                                           </li>
    //                                         </ul> */}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                              { false&&<Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Wig Details:
    //                                       <Header.Subheader>

    //                                         <ul>
    //                                           <li>
    //                                             Color:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .color
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Density:
    //                                             {
    //                                               order.wigDetails
    //                                                 .density
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Lace:{" "}
    //                                             {order.wigDetails.lace}
    //                                           </li>
    //                                           <li>
    //                                             Length:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .length
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Material:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .material
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Texture:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .texture
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Wig Part:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .wigPart
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Lace Color:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .laceColor
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Baby Hair:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .babyHair
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Bleached Knots:
    //                                             {
    //                                               order.wigDetails
    //                                                 .bleachedKnots
    //                                             }
    //                                           </li>
    //                                           <li>
    //                                             Bundles provided:{" "}
    //                                             {
    //                                               order.wigDetails
    //                                                 .bundlesProvided
    //                                             }
    //                                           </li>
    //                                         </ul>
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>}
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Quantity:
    //                                       <Header.Subheader>
    //                                         {order.items.length}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Total Amount:
    //                                       <Header.Subheader>
    //                                         ${order.uiOrderTotalAmount}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                               <Table.Row>
    //                                 <Table.Cell>
    //                                   <Header as="h4">
    //                                     <Header.Content>
    //                                       Amount Owed:
    //                                       <Header.Subheader>
    //                                         ${order.balance}
    //                                       </Header.Subheader>
    //                                     </Header.Content>
    //                                   </Header>
    //                                 </Table.Cell>
    //                               </Table.Row>
    //                             </Table.Body>
    //                           </Table>
    //                         </Table.Cell>

    //                         <Table.Cell>
    //                           <img
    //                             style={{ width: "120px", height: "120px" }}
    //                             src="https://xcdn.unice.com/media/catalog/product/cache/6/image/700x700/9df78eab33525d08d6e5fb8d27136e95/2/4/2457_1.jpg"
    //                           />
    //                           <img
    //                             style={{ width: "120px", height: "120px" }}
    //                             src="https://xcdn.unice.com/media/catalog/product/cache/6/image/700x700/9df78eab33525d08d6e5fb8d27136e95/2/4/2457_1.jpg"
    //                           />
    //                         </Table.Cell>
    //                         {/* STEP ONE ACCEPT/REJECT ORDER */}
    //                         <Table.Cell>
    //                           <Table.Row>
    //                             {orderShow === ORDERPLACE && (
    //                               <>
    //                                 <OverlayTrigger
    //                                   placement="top"
    //                                   overlay={
    //                                     <Tooltip id="button-tooltip-2">
    //                                       The order is approved
    //                                     </Tooltip>
    //                                   }
    //                                 >
    //                                   {({ ref, ...triggerHandler }) => (
    //                                     <Button
    //                                       variant="light"
    //                                       {...triggerHandler}
    //                                       className="d-inline-flex align-items-center"
    //                                       onClick={handleShowAccept}
    //                                     >
    //                                       <span ref={ref} className="ml-1">
    //                                         Accept Order
    //                                       </span>
    //                                     </Button>
    //                                   )}
    //                                 </OverlayTrigger>
    //                                 <OverlayTrigger
    //                                   placement="top"
    //                                   overlay={
    //                                     <Tooltip id="button-tooltip-2">
    //                                       Cannot accept the order
    //                                     </Tooltip>
    //                                   }
    //                                 >
    //                                   {({ ref, ...triggerHandler }) => (
    //                                     <Button
    //                                       variant="light"
    //                                       {...triggerHandler}
    //                                       className="d-inline-flex align-items-center"
    //                                       onClick={handleShowReject}
    //                                     >
    //                                       <span ref={ref} className="ml-1">
    //                                         Reject Order
    //                                       </span>
    //                                     </Button>
    //                                   )}
    //                                 </OverlayTrigger>{" "}
    //                               </>
    //                             )}
    //                             {orderShow === ACCEPTED && (
    //                               <span className="text">
    //                                 {acceptedDate}: Order Accepted{" "}
    //                               </span>
    //                             )}
    //                             {orderShow === REJECTED && (
    //                               <span className="text">
    //                                 {rejectedDate}: Order Rejected
    //                               </span>
    //                             )}
    //                           </Table.Row>
    //                           {/* STEP TWO DISPLAY TRACK # IF CLIENT SENDS OWN BUNDLES, TAKE FROM ORDER DETAILS DATA */}
    //                           <Table.Row>
    //                             {orderShow === ACCEPTED && (
    //                               <span>Client's tracking number:</span>
    //                             )}
    //                           </Table.Row>
    //                           <Table.Row>
    //                             {orderShow === ACCEPTED && (
    //                               <span className="text">
    //                                 Canada Post: 28495037njfkdkh
    //                               </span>
    //                             )}
    //                           </Table.Row>
    //                           {/* STEP THREE DISPLAY START WIG PROCESS BUTTON WITHIN DISPLAYING TRACKING NUMBER*/}
    //                           <Table.Row>
    //                             {orderInProcess === NOTPROCESS && (
    //                               <OverlayTrigger
    //                                 placement="top"
    //                                 overlay={
    //                                   <Tooltip id="button-tooltip-2">
    //                                     I am ready to start customize item.
    //                                   </Tooltip>
    //                                 }
    //                               >
    //                                 {({ ref, ...triggerHandler }) => (
    //                                   <Button
    //                                     variant="light"
    //                                     {...triggerHandler}
    //                                     className="d-inline-flex align-items-center"
    //                                     onClick={confirmShow}
    //                                   >
    //                                     <span ref={ref} className="ml-1">
    //                                       Start wig process
    //                                     </span>
    //                                   </Button>
    //                                 )}
    //                               </OverlayTrigger>
    //                             )}
    //                             {orderInProcess === INPROCESS && (
    //                               <span className="text">
    //                                 {startDate}: Wig in the process
    //                               </span>
    //                             )}
    //                           </Table.Row>
    //                           {/* STEP FOUR DISPLAYING TRACKING NUMBER & SERVICE OPTION FORM*/}
    //                           <Table.Row>
    //                             {placeShip === SHIPPED && (
    //                               <>
    //                                 <span>{shippedDate}: Order shipped</span>
    //                               </>
    //                             )}
    //                           </Table.Row>
    //                           <Table.Row>
    //                             {placeShip === NOTSHIPPED && (
    //                               <>
    //                                 <p>Ready for shipping</p>
    //                                 <Form.Group>
    //                                   <Form.Control
    //                                     as="select"
    //                                     name="deliveryName"
    //                                     onChange={(e) =>
    //                                       setDeliveryName(e.target.value)
    //                                     }
    //                                   >
    //                                     <option>Canada Post</option>
    //                                     <option>UPS</option>
    //                                     <option>FedEx</option>
    //                                     <option>US Post</option>
    //                                   </Form.Control>
    //                                   <Form.Control
    //                                     type="text"
    //                                     name="deliveryTrack"
    //                                     placeholder="Normal text"
    //                                     onChange={(e) =>
    //                                       setDeliveryTrack(e.target.value)
    //                                     }
    //                                   />
    //                                 </Form.Group>

    //                                 <OverlayTrigger
    //                                   placement="top"
    //                                   overlay={
    //                                     <Tooltip id="button-tooltip-2">
    //                                       Send the traking number to customer
    //                                     </Tooltip>
    //                                   }
    //                                 >
    //                                   {({ ref, ...triggerHandler }) => (
    //                                     <Button
    //                                       variant="light"
    //                                       {...triggerHandler}
    //                                       className="d-inline-flex align-items-center"
    //                                       onClick={handleShowShipping}
    //                                     >
    //                                       <span ref={ref} className="ml-1">
    //                                         Submit
    //                                       </span>
    //                                     </Button>
    //                                   )}
    //                                 </OverlayTrigger>
    //                               </>
    //                             )}
    //                             {placeShip === SHIPPED && (
    //                               <>
    //                                 <span className="text">
    //                                   {" "}
    //                                   {deliveryName}: {deliveryTrack}
    //                                 </span>
    //                               </>
    //                             )}
    //                           </Table.Row>
    //                           <Table.Row>
    //                             {orderFinished === NOTSHIPPED && (
    //                               <OverlayTrigger
    //                                 placement="top"
    //                                 overlay={
    //                                   <Tooltip id="button-tooltip-2">
    //                                     Finalize your order completion
    //                                   </Tooltip>
    //                                 }
    //                               >
    //                                 {({ ref, ...triggerHandler }) => (
    //                                   <Button
    //                                     variant="light"
    //                                     {...triggerHandler}
    //                                     className="d-inline-flex align-items-center"
    //                                     onClick={confirmWigStartShow}
    //                                   >
    //                                     <span ref={ref} className="ml-1">
    //                                       Order completed
    //                                     </span>
    //                                   </Button>
    //                                 )}
    //                               </OverlayTrigger>
    //                             )}
    //                             {orderFinished === FINISHED && (
    //                               <span className="text">
    //                                 {finishedDate}: Order completed!{" "}
    //                               </span>
    //                             )}
    //                           </Table.Row>
    //                         </Table.Cell>
    //                       </>
    // }
    //                 </Table.Row>
    //               </Table.Body>
    //             </Table>

    //             {/*POP UP WINDOWS  */}
    //             <Modal
    //               show={showAccept}
    //               onHide={handleCloseAccept}
    //               dialogClassName="modal-90w"
    //               aria-labelledby="example-custom-modal-styling-title"
    //             >
    //               <Modal.Header closeButton>
    //                 <Modal.Title id="example-custom-modal-styling-title">
    //                   Comfirmation of accepting order
    //                 </Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <p>I accepted you order</p>
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleCloseAccept}>
    //                   Close
    //                 </Button>
    //                 <Button
    //                   variant="primary"
    //                   onClick={(e) => {
    //                     setOrderShow(ACCEPTED);
    //                     setInProcess(NOTPROCESS);
    //                     handleCloseAccept();
    //                   }}
    //                 >
    //                   Confirm
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>

    //             <Modal
    //               show={showReject}
    //               onHide={handleCloseReject}
    //               dialogClassName="modal-90w"
    //               aria-labelledby="example-custom-modal-styling-title"
    //             >
    //               <Modal.Header closeButton>
    //                 <Modal.Title id="example-custom-modal-styling-title">
    //                   Message customer
    //                 </Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <Form.Control as="textarea" rows={3} />
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleCloseReject}>
    //                   Close
    //                 </Button>
    //                 <Button
    //                   variant="primary"
    //                   onClick={(e) => {
    //                     setOrderShow(REJECTED);
    //                     handleCloseReject();
    //                   }}
    //                 >
    //                   Send
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>

    //             <Modal
    //               show={confShow}
    //               onHide={confirmClose}
    //               dialogClassName="modal-90w"
    //               aria-labelledby="example-custom-modal-styling-title"
    //             >
    //               <Modal.Header closeButton>
    //                 <Modal.Title id="example-custom-modal-styling-title">
    //                   Confirmation
    //                 </Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <p>I've started custimize your wig</p>
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={confirmClose}>
    //                   Close
    //                 </Button>
    //                 <Button
    //                   variant="primary"
    //                   onClick={(e) => {
    //                     setInProcess(INPROCESS);
    //                     setPlaceShip(NOTSHIPPED);
    //                     confirmClose();
    //                   }}
    //                 >
    //                   Confirm
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>

    //             <Modal
    //               show={showShipping}
    //               onHide={handleCloseShipping}
    //               dialogClassName="modal-90w"
    //               aria-labelledby="example-custom-modal-styling-title"
    //             >
    //               <Modal.Header closeButton>
    //                 <Modal.Title id="example-custom-modal-styling-title">
    //                   Confirmation of shipping details
    //                 </Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <p>Canada Post: i1289073jekoo</p>
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleCloseShipping}>
    //                   Close
    //                 </Button>
    //                 <Button
    //                   variant="primary"
    //                   onClick={(e) => {
    //                     setFinished(NOTSHIPPED);
    //                     setPlaceShip(SHIPPED);
    //                     handleCloseShipping();
    //                   }}
    //                 >
    //                   Confirm
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>

    //             <Modal
    //               show={confWigStartShow}
    //               onHide={confirmWigStartClose}
    //               dialogClassName="modal-90w"
    //               aria-labelledby="example-custom-modal-styling-title"
    //             >
    //               <Modal.Header closeButton>
    //                 <Modal.Title id="example-custom-modal-styling-title">
    //                   Conformation of finishing order
    //                 </Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <p>the custom wig is completed</p>
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={confirmWigStartClose}>
    //                   Close
    //                 </Button>
    //                 <Button
    //                   variant="primary"
    //                   onClick={(e) => {
    //                     setFinished(FINISHED);
    //                     confirmWigStartClose();
    //                   }}
    //                 >
    //                   Confirm
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>
    //           </div>


  );
}

export default InvoiceDetails;

