import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
// import "./WigListing.css";
import { Link } from "react-router-dom";
// import Rating from '@material-ui/lab/Rating';
// import { BrowserRouter as Router, Route } from "react-router-dom";
// import ReactDOM from "react-dom";
function discountPercentage(originalPrice, currentPrice) {
    var percent = currentPrice - originalPrice;
    percent = percent / originalPrice * 100
    return Math.abs(percent);
}

const SellerCard = (props) => {
    return (
        <>
            {props?.creator && <>
                {
                    // <div className="creatorCard-content" >
                        <Col sm={3} key={props?.index + 'wigListing'} >
                            <div className="roundCard-container">
                                <Link
                                    to={{
                                        pathname: "/view-wigmaker-page/" + props.creator?.businessInfo.businessId,
                                        state: { creator: props.creator }
                                    }}
                                >
                                    <div className="creater-img">/
                                        <Image className={'p-0'} src={props.creator?.businessInfo.businessLogo} roundedCircle />
                                    </div>
                                    <h4 >{props.creator?.businessInfo?.businessName}</h4>
                                    <h4 >{props.creator?.businessInfo?.businessAddress?.city}, {props.creator?.businessInfo?.businessAddress?.country}</h4>
                                
                                </Link>
                            </div>
                        </Col>
                    // </div>
                }
            </>}
        </>
    )
}

export default SellerCard;