import { createSlice } from '@reduxjs/toolkit'

export const itemSlice = createSlice({
    name: 'item',
    initialState: {
        wigs: [],
        options: [],
        
        
    },
    reducers: {
        updateWigs: (state, action) => {
            state.wigs = action.payload;
        },
        updateOptions: (state, action) => {
            state.options = action.payload;
        },
       
    }
})
//Action Creators are generated for each reducer function
export const { updateWigs, updateOptions } = itemSlice.actions

export default itemSlice.reducer