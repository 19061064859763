import React, { useEffect, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./SellerServices.css";
import { Rating, Label } from 'semantic-ui-react'
import  Repair  from '../../../../assets/repair.png'
import  hairdryer  from '../../../../assets/hairdryer.png'
import  sewing  from '../../../../assets/sewing.png'





export const SellerServices = (props) => {

    const prop = { ...props };

    useEffect(() => {
        console.log('room man enters', props)
    }, [])
    return (
        <div className="premadeWigs-box">
            <h3>My Services</h3>
            <Row>
                <Col sm={3} >
                    <Card as={Link} >
                        <Card.Img variant="top" src={sewing} />
                        <Card.Body>

                            <Card.Title >Custom Wigs</Card.Title>
                            <Card.Subtitle as={Link} className="mb-2 text-muted">
                                <div>
                                    <b> CA$20</b>
                                </div>
                                <Label size={'mini'} as='a' color={'red'} tag>
                                   Not Available
                                </Label>

                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3} >
                    <Card as={Link} >
                        <Card.Img variant="top" src={hairdryer} />
                        <Card.Body>

                            <Card.Title >Wig Installation</Card.Title>
                            <Card.Subtitle as={Link}  className="mb-2 text-muted">
                                <div>
                                    <b> CA$90</b>
                                </div>
                                <Label size={'mini'} as='a' color={'orange'} tag>
                                    August Full Booked
                                </Label>

                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3} >
                    <Card as={Link} >
                        <Card.Img variant="top" src={Repair} />
                        <Card.Body>

                            <Card.Title >Wig Repair</Card.Title>
                            <Card.Subtitle as={Link}  className="mb-2 text-muted">
                                <div>
                                    <b> CA$50</b>
                                </div>
                                <Label size={'mini'} as='a' color={'red'} tag>
                                  Not  Available
                                </Label>

                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            


            {/* <Row>
                {
                props.wigs && props.wigs.map((data) => {
                    return (
                        <Col sm={3} >
                            <Card as={Link} to={{
                                pathname: "/wig/" + data.wigId,
                                state: { wig: data }
                            }}>
                                <Card.Img variant="top" src={data.images[0].originalUrl} />
                                <Card.Body>
                                    
                                    <Card.Title >{data.name}</Card.Title>
                                    <Card.Subtitle as={Link} to={{ pathname: "/wig/" + data.wigId, state: { wig: data } }}
                                        className="mb-2 text-muted">
                                        <div>
                                            <b> CA${data.price}</b>
                                        </div>
                                        <Label size={'mini'} as='a' color={data.quantity > 0 ? 'teal':'green'} tag>
                                            {data.quantity > 0 ? "Ready To Ship" : "Can Be Made"}
                                        </Label>
                                       
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
                )
                }


            </Row> */}

        </div>
    )
}



export default SellerServices;