import React, { useEffect, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./PremadeWigs.css";
import { Rating, Label } from 'semantic-ui-react'





export const PremadeWigs = (props) => {

    const prop = { ...props };

    useEffect(() => {
        console.log('room man enters', props)
    }, [])
    return (
        <div className="premadeWigs-box">
            <h3>My Wigs</h3>
            {/* <Row>
                <Col sm={4}>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Name</Card.Title>
                           
                            <Button as={Link} variant="primary" >Create Wig</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}

            <Row>
                {props.wigs && props.wigs.map((data) => {
                    return (
                        <Col sm={3} >
                            <Card as={Link} to={{
                                pathname: "/wig/" + data.wigId,
                                state: { wig: data }
                            }}>
                                <Card.Img variant="top" src={data.images[0].originalUrl} />
                                <Card.Body>
                                    
                                    <Card.Title >{data.name}</Card.Title>
                                    <Card.Subtitle as={Link} to={{ pathname: "/wig/" + data.wigId, state: { wig: data } }}
                                        className="mb-2 text-muted">
                                        <div>
                                            <b> CA${data.price}</b>
                                        </div>
                                        <Label size={'mini'} as='a' color={data.quantity > 0 ? 'teal':'green'} tag>
                                            {data.quantity > 0 ? "Ready To Ship" : "Can Be Made"}
                                        </Label>
                                        {/* <div>
                                         <Rating  maxRating={5} defaultRating={data.rating} disabled />  
                                         </div> */}

                                        {/* <Col>
                                         <Row>
                                         CA${data.price} <Rating className={'ml-3 mr-2'} maxRating={5} defaultRating={data.rating} disabled />
                                         </Row>
                                         
                                         </Col> */}
                                    </Card.Subtitle>
                                    {/* <Button as={Link} variant="primary" to={`/view-wigmaker-page/${dummyData.childKey}`}>Go somewhere</Button> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
                )}


            </Row>

        </div>
    )
}




// return wigMakerList.map(wigMaker => {
//     return (
//         <Col sm={4}>
//             <Card style={{ width: '18rem' }} key={wigMaker.childKey}>
//                 <Card.Img variant="top" src={wigMaker.image} />
//                 <Card.Body>
//                     <Card.Title>{wigMaker.name}</Card.Title>
//                     <Card.Subtitle className="mb-2 text-muted">{wigMaker.location}</Card.Subtitle>
//                     <Card.Text>
//                         {wigMaker.description}
//                         Some quick example text to build on the card title and make up the bulk of
//                         the card's content.
//                  </Card.Text>
//                     <Button as={Link} variant="primary" to={`/view-wigmaker-page/${wigMaker.childKey}`}>Go somewhere</Button>
//                 </Card.Body>
//             </Card>
//         </Col>

//     );
// });




export default PremadeWigs;