import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../redux/user/userSlice';
import { firestore } from '../../services/firebase'
import _ from 'lodash';
import { Image } from "semantic-ui-react";
import { FaTimes } from 'react-icons/fa';
import OrdersDetailsModel from '../shared/OrdersDetailsModal'

function CartItemComponent(props) {
    const userCart = useSelector((state) => state.User.cart)
    const userId = useSelector((state) => state.User.userId)
    const [showDetails, setShowDetails] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('ross is fumnnyd', props)

    }, [])

    function remove() {
        var tempItems = _.cloneDeep(userCart);
        tempItems.splice(props.index, 1)
        dispatch(updateCart(tempItems));
        firestore.collection('users/' + userId + '/cart').doc(props.item.cartId).update({ displayInCart: false });


    }


    return (
        <>

            {/* <Container>
                <img src={props.item.images[0]} />
            </Container> */}
            <Container >
                <OrdersDetailsModel show={showDetails} item={props.item} updateShow={() => { setShowDetails(false) }} />
                <Row className='p-4'>

                    <Col >
                        <Image src={props.item.images[0].originalUrl} size='medium' />
                        {/* <Button variant='link' color='gray' size='lg' onClick={() => { remove() }} ><FaTimes /> </Button> */}
                        {/* <img style={{width:'50%',height:'50%'}} src={props.item.images[0].originalUrl} /> */}
                    </Col>
                    <Col sm={9}>

                        <b style={{ fontSize: '20px' }} >{props.item.name}</b>
                        <div style={{ float: 'right' }} >
                            <Button variant="warning" size='sm' onClick={() => { setShowDetails(true) }} >Details </Button>
                        </div>
                        <p>{props.item.description}</p>

                        <b>Qty:</b> 1
                        <div>
                            <b>Subtotal:</b> CA${props.item?.finalPrice || props.item?.price}
                        </div>


                        {!props.hideDelete && <div className='mt-4' >
                            <Button variant="link" size='sm' onClick={() => { remove() }} >Delete </Button>
                        </div>}
                        {/* <h3>CA${props.item?.finalPrice || props.item?.price}</h3>
                        <b> {props.item.name}-{props.item.description}</b>

                        <h4>Qty: 1</h4> */}

                    </Col>
                    <Col >

                        {/* <h3>CA${props.item?.finalPrice || props.item?.price}</h3> */}
                        {/* <FaCheckCircle  />  */}
                        {/* <Button variant='link' color='gray' size='lg' onClick={() => { remove() }} ><FaTimes /> </Button> */}
                    </Col>
                </Row>
                {!props?.isLastIndex && <hr />}
            </Container>


        </>
    )
}

export default CartItemComponent;