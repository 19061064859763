import React from 'react';
import "./CustomWigDetails.css";
import moment from "moment";
import { List, Icon, Container, Button } from "semantic-ui-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// import history from "../../../../history.js";

import handmade from "../../../../assets/handmade.png";
import giftbox from "../../../../assets/gift-box.png";
import wig from "../../../../assets/wig.png";
import chronometer from "../../../../assets/chronometer.png";
import CustomOfferings from '../CustomOfferings/CustomOfferings';
import hello from "../../../../assets/hello.png";
import fastDelivery from "../../../../assets/fast-delivery.png";
import gift from "../../../../assets/gift.png";

function CustomWigDetails(props) {

let placedDate = moment().format("D MMMM ");
// let startProcessDate = moment().add(4, "days").format("D");
// let endProcessDate = moment().add(8, "days").format("D MMMM");
let earlyDeliveryDate = moment().add(1, "week").format("D");
let latestDeliveryDate = moment().add(2, "weeks").format("D MMMM");

const renderTooltipOne = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Give approxitamete time of delivery
  </Tooltip>
);
const renderTooltipTwo = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Can you provide your own bunddles
  </Tooltip>
);
    return (
      <div className="customWigDetails-box">
        <h1>Customize Wigs</h1>
        <Row>
          <Col col={2}>
            <h4>
              Turn around time{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipOne}
              >
                <Icon fitted name="question circle" size="tiny" />
              </OverlayTrigger>
              :
            </h4>
          </Col>
          <Col col={10}>
            <p>
              The time I need to prepare an order and to deliver takes 2 to 4
              weeks
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>Estimated arrival</p>
            <span>
              {earlyDeliveryDate} - {latestDeliveryDate}
            </span>
          </Col>
        </Row>
        <Row className="delivery-wrap">
          <div>
            <img src={hello} />
            <p>{placedDate}</p>
            <p className="text-delivery">Order placed</p>
          </div>
          <hr />
          <div>
            <img src={gift} />
            <p>
              {earlyDeliveryDate} - {latestDeliveryDate}
            </p>
            <p className="text-delivery">Delivered!</p>
          </div>
        </Row>

        <Row className="wrap">
          <hr />
          <img src={chronometer} />
          <hr />
        </Row>

        <Row>
          <Col col={2}>
            <h4>
              I provide Bundles{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipTwo}
              >
                <Icon fitted name="question circle" size="tiny" />
              </OverlayTrigger>
              :
            </h4>
          </Col>
          <Col col={10}>
            <p>
              I have different type of bunddles you can select from my store{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col col={2}>
            <h4>
              I accept Bundles{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipTwo}
              >
                <Icon fitted name="question circle" size="tiny" />
              </OverlayTrigger>
              :
            </h4>
          </Col>
          <Col col={10}>
            <p>you can drop off or deliver to me</p>
          </Col>
        </Row>
        <Row className="wrap">
          <hr />
          <img src={wig} />
          <hr />
        </Row>
        <Row>
          <Col col={2}>
            <h4>
              Delivery{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipTwo}
              >
                <Icon fitted name="question circle" size="tiny" />
              </OverlayTrigger>
              :
            </h4>
          </Col>
          <Col col={10}>
            <h5>Processing Time</h5>
            <p>
              The time I need to prepare an order for shipping varies. For
              details, see individual items.
            </p>
            <h5>Estimated shipping times </h5>
            <p>
              I'll do my best to meet these shipping estimates, but cannot
              guarantee them. Actual delivery time will depend on the shipping
              method you choose.
            </p>
            <h5>Customs and import taxes</h5>
            <p>
              Buyers are responsible for any customs and import taxes that may
              apply. I'm not responsible for delays due to customs.
            </p>
          </Col>
        </Row>
        <Row className="wrap">
          <hr />
          <img src={giftbox} />
          <hr />
        </Row>
        {/* <Row> */}
        <CustomOfferings />
        {/* </Row> */}
        <Row className="wrap">
          <hr />
          <img src={handmade} />
          <hr />
        </Row>
        <Row>
          <Button size="medium" onClick={props.displayComponent}>
            <span>Create Custom Wig</span>
          </Button>
        </Row>
      </div>
    );
}

export default CustomWigDetails;
