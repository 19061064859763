import React from "react";
import "./StyleCategoriesSection.css";

import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';


import Kinky from "../../assets/kinky-hair.jpg";
import Straight from "../../assets/straightHair.jpg";
import Blonde from "../../assets/blonde-kinky-hair.jpg";
import other from "../../assets/white-hair.jpg";
import frontalImage from "../../assets/frontal.png";
// import closureImage from "../../assets/Closure.png";


function TextureColorTypeComponent(){ 

    return (
        <div className="lace-container">
            <ul className="type-link">
                <li className="kink-link">
                    <Link to="/wigs/kinky">
                    <div className="square-box-img">
                        <Image src={Kinky} />
                    </div>
                        <p>Kinky</p>
                    </Link>
                </li>
                <li className="kink-link">
                    <Link to="/wigs/straight">
                        <div className="square-box-img">
                            <Image src={Straight} />
                        </div>
                        <p>Straight</p>
                    </Link>
                </li>
                <li className="other-link">
                    <Link to="/wigs/blonde">
                    <div className="square-box-img">
                        <Image src={Blonde} />
                    </div>
                        <p>Blonde</p>
                    </Link>
                </li>
                <li className="other-link">
                    <Link to="/wigs/synthetic">
                    <div className="square-box-img">
                        <Image src={other} />
                    </div>
                        <p>Synthetic</p>
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default TextureColorTypeComponent;