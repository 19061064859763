import React, { useEffect, useState,useRef } from "react";
import Card from "react-bootstrap/Card";
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import firebase ,{ firestore, storage } from '../../../services/firebase'
const AddReview = (props) => {
  const [open, setOpen] = React.useState(false)
  const userId = useSelector((state) => state.Admin.userId)
  const reviewFormRef = useRef();
  var storageRef = storage.ref();
  function uploadToStorage(name, file,values) {

    console.log('machine', props.wigId)

    storageRef.child('/reviews/' + props.productId + '/' + userId + '/' + name).put(file).then(snapshot => {
        snapshot.ref.getDownloadURL().then(url => {
           values['image'] =url
            // firestore.collection('/users/' + userId + '/wigCreator/preMadeWigs/wigs').doc(props.wigId).update({ images: firebase.firestore.FieldValue.arrayUnion({ name: name, url: url }) })
            firestore.collection('/wigs').doc(props.productId).update({ reviews: firebase.firestore.FieldValue.arrayUnion( values ) })

            
        })


    })
}

function uploadFile(file,values) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  uploadToStorage(file.name, file,values)
  
}

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Show Modal</Button>}
    >
      <Modal.Header>Leave A Review</Modal.Header>
      <Modal.Content >

        <Modal.Description>


          <Formik
          innerRef={reviewFormRef}
            initialValues={{ productId: props.productId, comment: '', headline: '', rating: 0, postedAt: moment().unix(), userName: 'private', userId: userId, image: null }}
            onSubmit={(values)=>{
              console.log('data reviews',values)
              // firestore.collection('/wigs').doc(props.productId).update({ reviews: firebase.firestore.FieldValue.arrayUnion({ name: name, url: url,image:url }) })

              if(values.image){
              uploadFile(values.image,values)
            }else{
              firestore.collection('/wigs').doc(props.productId).update({ reviews: firebase.firestore.FieldValue.arrayUnion(values) })
            }

            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <>
                <Col>
                  <Col>
                    <Rating maxRating={5} name={'rating'} icon='star' size={'massive'} onRate={(val,data)=>{setFieldValue('rating',data.rating)}} defaultRating={0} />
                  </Col>
                  <Col>
                    <FormLabel className={'mt-2'}><h3>Comment</h3></FormLabel>
                    <FormControl
                      size={'lg'}
                      rows={3}
                      name={'comment'}
                      as="textarea"
                      value={values.comment}
                      onChange={handleChange} />
                  </Col>
                  <Col>

                    <FormControl
                      className={'mt-2'}
                      placeholder={'Headline for review'}
                      name={'headline'}
                      as="input"
                      value={values.headline}
                      onChange={handleChange} />
                  </Col>
                  <Col>
                  <label for="img">Add Photo: </label>
                  <input id="img" name="img" type="file" accept="image/*" onChange={(event) => {
                    setFieldValue("image", event.currentTarget.files[0]);
                  }} />
                  </Col>
                </Col>
              </>
            )}
          </Formik>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
      </Button>
        <Button
          content="Submit"
          labelPosition='right'
          icon='checkmark'
          onClick={() => reviewFormRef.current.handleSubmit()}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AddReview;