import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        orders: [],
        adminInfo: {},
        loggedIn: false,
        userId:''
    },
    reducers: {
        updateOrders: (state, action) => {
            state.orders = action.payload;
        },
        updateAdminInfo: (state, action) => {
            state.adminInfo = action.payload;
        },
        updateLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        updateUserId:(state, action) => {
            state.userId = action.payload;
        }
    }
})
//Action Creators are generated for each reducer function
export const { updateLoggedIn, updateAdminInfo, updateOrders,updateUserId } = adminSlice.actions

export default adminSlice.reducer