import React, { useEffect, useState, useRef,forwardRef,useCallback } from "react";
import "../Checkout.css"
import firestoreService from '../../../services/firestoreService';
import { useSelector, useDispatch } from 'react-redux';



function DeliveryAddressDetailsComponent(props) {
    useEffect(()=>{

    },[])


    return(
        <div>
            <hr/>
<h1>Delivery</h1>
        </div>
    )
}

export default DeliveryAddressDetailsComponent;