import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Image, Divider } from "semantic-ui-react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import WigListing from '../WigListing/WigListing'
import WigCreatorListing from '../WigCreator/WigCreatorListing'
import './Section.css'
import { Icon, Input } from "semantic-ui-react";




export default function Section(props) {
  const [pathName, setPathName] = useState("/wigs/" + props.filter || '');

  useEffect(() => {
    setPathName("/wigArtists/" + props.filter || '')

  }, [])


  return (
    <div className="section-container">
      <Col >
        <div className="top-section" >
          <h2><span>{props.header}</span></h2>

          {/* {props.display === 'WIG'&&<Link
            to={{
              pathname: pathName,
              state: { wig: props.wig }
            }}
          >
            <div className="seeMore-box">
              <h3>See More</h3>
              <Icon className='mt-1' name="angle right" size='large' />
            </div>
          </Link>} */}
        </div>
        <div>
        {props.display === 'WIG' ? <WigListing limit={4} filter={props.filter} landing={true} /> : <WigCreatorListing landing={true} limit={4} filter={props.filter} />}
        </div>
      </Col>

    </div>
  )
}