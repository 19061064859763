import * as Yup from 'yup';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AddressValidationSchema = Yup.object().shape({

    firstName: Yup.string() .required('Required'),
    lastName: Yup.string() .required('Required'),
    address: Yup.string().min(2) .required('Required'),
    addressTwo: Yup.string() .required('Required'),
    city: Yup.string() .required('Required'),
    country: Yup.string() .required('Required'),
    province: Yup.string() .required('Required'),
    postal: Yup.string() .required('Required'),
    phoneNumber:Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'), 
    email: Yup.string().email('Invalid email').required('Required'),
 
  });

// export  {AddressValidationSchema}