import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment'


const ViewReview = (props) => {
  useEffect(() => {
    console.log('hahahah', props.reviews)

  }, [])

  //Todo format, also add uploaded image
  return (
    <>
      {props.reviews.map(review => {
        return (
          <>
            <Col className='m-2' style={{borderStyle:'solid'}} >
            <h4>{review.headline} </h4>
              <Row>
                <Col>{review.userName} on {moment.unix(review.postedAt).local().format('LL')}</Col>
              </Row>
              <Row>
                <Col><Rating maxRating={5} defaultRating={review.rating} disabled /></Col>
              </Row>
              <Row>
                <Col><p>{review.comment}</p> </Col>
              </Row>
            </Col>
          </>
        )
      })}
      
    </>
  )
}

export default ViewReview;