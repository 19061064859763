import firebase from 'firebase';
// Firebase Config
const firebaseConfig = {
    apiKey: "AIzaSyDig2jB4uU8Ubx6YUWsdcrWXxWpg1O9KzE",
    authDomain: "yornel-wigs.firebaseapp.com",
    projectId: "yornel-wigs",
    storageBucket: "yornel-wigs.appspot.com",
    messagingSenderId: "126793456105",
    appId: "1:126793456105:web:1cc7ad2231ef00e8b49378",
    measurementId: "G-XTZZ8S542Q"
};

// export default { firebaseConfig }
firebase.initializeApp(firebaseConfig);
export default firebase;
 

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
