import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import "./ItemComponent.css";
import moment from "moment";
import Select from "react-select";
import { firestore } from "../../../../services/firebase.js";
import firestoreService from "../../../../services/firestoreService.js";
import ViewReview from "../../../shared/Review/ViewReview"
import AddReview from "../../../shared/Review/AddReview"


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';

import { Image, Icon, Label } from "semantic-ui-react";

import gift from "../../../../assets/gift.png";
import hello from "../../../../assets/hello.png";
import _ from 'lodash';
import { Rating } from 'semantic-ui-react'

import { useSelector, useDispatch } from 'react-redux';
import { updateCart, updateCartStylistInfo, updateSelectedInstallation, updateOrderNotes } from '../../../../redux/user/userSlice'

import ReadMoreAndLess from '../../../shared/ReadMoreAndLess'

// import PresetStyles from '../../../../WigMakerView/OrderPageEdit/CustomWigForm/Forms/PresetStyles';



export const ItemComponent = (props) => {
  const userId = useSelector((state) => state.User.userId)
  let { id } = useParams();
  const [wigOptions, setWigOptions] = useState({});
  const [wigCustomize, setWigCustomize] = useState([]);
  const [businessInfo, setBusinessInfo] = useState();
  const [installationOptions, setInstallationOptions] = useState();
  const [earlyDeliveryDate, setEarlyDeliveryDate] = useState();
  const [latestDeliveryDate, setLatestDeliveryDate] = useState();
  const [wig, setWig] = useState({});
  const [price, setPrice] = useState({ currentPrice: 0 });
  const itemFormRef = useRef();
  const dispatch = useDispatch();
  const userCart = useSelector((state) => state.User.cart);
  let history = useHistory();


  let placedDate = moment().format("D MMMM ");
  // let earlyDeliveryDate = moment().add(1, "week").format("D MMMM");
  // let latestDeliveryDate = moment().add(2, "weeks").format("D MMMM");

  useEffect(() => {
    // console.log('wiggsssss',this.props?.location?.state)
    // firestore.collection("users/" + userId + "/wigCreator/preMadeWigs/wigs/")
    //   .doc(wigId)
    //   .onSnapshot((doc) => {
    //     const presetStylesInfo = doc.data();
    //     setWigInfo(presetStylesInfo);
    //     console.log("inside set", doc.data() );
    //   });

    // firestore.collection("users/" + userId + "/wigCreator/")
    //   .doc("customiziableWigOptions")
    //   .onSnapshot((doc) => {
    //     const wigCustomizedInfo = doc.data();
    //     setWigCustomize(wigCustomizedInfo);
    //     console.log("inside wig details", doc.data());
    //   });

    // firestore.collection("users/" + userId + "/wigCreator/")
    //   .doc("businessInformation")
    //   .onSnapshot((doc) => {
    //     const businessInfo = doc.data();
    //     setBusinessInfo(businessInfo);
    //     console.log("inside info", doc.data());
    //   });

    // console.log('show da cart boiiii', businessInfo.shipping?.shippingProfile?.shippingCostMethod)


  }, [userCart]);

  function updatePrice(objName, item) {
    var tempPrice = _.cloneDeep(price); // full price obj describes the current price
    var currentPrice = parseInt(wig.price) // wig price is the base price
    if (objName === 'installation' || (wig[objName].value !== item.value)) { // need to check if using base wig to the price does not increase if base wig has been selected
      tempPrice[objName] = { price: parseInt(item.price || 0), name: item.name }

      console.log('show temp', tempPrice)
      for (const [key, value] of Object.entries(tempPrice)) {
        console.log(`${key}: ${value}`);
        if (typeof value === 'object' && value !== null) { // check if its an object
          currentPrice = currentPrice + value.price;
        }
      }
      console.log(currentPrice, 'current price');
      tempPrice.currentPrice = currentPrice;
      setPrice(tempPrice)

    } else {
      if (tempPrice[objName]) {
        currentPrice = parseInt(tempPrice.currentPrice) - parseInt(tempPrice[objName].price || 0);
        tempPrice.currentPrice = currentPrice

        delete tempPrice[objName];
        console.log('current Price falsex', tempPrice)
        setPrice(tempPrice)
      }
    }
  }



  useEffect(() => {
    let options = wig?.customizable;
    if (wig) {
      var tempPrice = _.cloneDeep(price)
      tempPrice.currentPrice = parseInt(wig.price)
      setPrice(tempPrice)

      console.log('doctor', wig.wigCreatorId)
      firestoreService.getWigCreatorInfo(wig.wigCreatorId).get().then(doc => {
        console.log('friends', doc.data())
        if (doc.data()) {
          const timeType = doc.data().shipping.shippingProfile.processingTime.inWeeks ? 'weeks' : 'days';


          setBusinessInfo(doc.data());
          setEarlyDeliveryDate(moment().add(wig.quantity > 0 ? 1 : parseInt(doc.data().shipping.shippingProfile.processingTime.from || 0), wig.quantity > 0 ? 'days' : timeType).format("D MMMM"));
          setLatestDeliveryDate(moment().add(wig.quantity > 0 ? 2 : parseInt(doc.data().shipping.shippingProfile.processingTime.to || 0), wig.quantity > 0 ? 'days' : timeType).format("D MMMM"));
        }

      })
      // firestoreService.getWigCreatorInfo(wig.wigCreatorId ).onSnapshot((doc) => {
      //   // const businessInfo = doc.data();
      //   // setBusinessInfo(businessInfo);
      //   doc.docs.map(doc => {
      //     if (doc.data()) {
      //       if (doc.id === 'businessInformation') {
      //         setBusinessInfo(doc.data());
      //       }
      //       if (doc.id === 'installationOptions' && doc.data().installation) {
      //         var tempInst = _.cloneDeep(doc.data().installation);
      //         console.log('installationOptions', tempInst)
      //         tempInst.push({description:'none',label:'None',listingStatus: true,name:'none',price:0,value:'none'})

      //         setInstallationOptions(tempInst)

      //       }
      //     }
      //   })

      // });
    }
  }, [wig])

  function discountPercentage(originalPrice, currentPrice) {
    var percent = currentPrice - originalPrice;
    percent = percent / originalPrice * 100
    return Math.abs(percent).toFixed(2);
  }





  useEffect(() => {
    if (props?.location?.state?.wig) {
      // setValue(props?.location?.state.wig);
      setWig(props?.location?.state.wig);
    } else {
      firestoreService.getWig(id).then(data => {
        setWig(data.data())
      })
    }
  }, [])

  function checkValueUpdated(varName, data) {
    var isUpdated = false;
    console.log('compare', wig[varName], data)
    if (wig[varName] !== data) {
      isUpdated = true;
    }
    return isUpdated;
  }


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  function handleRating(val) {
  }



  return (
    <div className="item-box">
      {wig.images && businessInfo && <Row>
        <Col sm={7}>
          <div className="gallery-box">
            <div className="box1">
              {wig?.images && wig?.images.map((nestedList, index) => {
                return (
                  <div className="img-box">
                    <Image
                      src={nestedList.originalUrl}
                      onClick={() => handleSelect(index)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="box2">
              <Carousel
                onSelect={handleSelect}
                interval={null}
                activeIndex={index}
              >
                {wig?.images.map((nestedList) => (
                  <Carousel.Item>
                    <Image src={nestedList.originalUrl} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>

          <div className="description-box">
            <div className="title-sm-7">
              <h3>{businessInfo?.businessInfo?.businessName}</h3>
              <Icon name="map marker" />
              <span className="small-text underline-text">{businessInfo?.businessInfo?.businessAddress?.city}</span>
              <br />

              <h1>{wig.name}</h1>
              
              <div className='mb-2'> 
              <Rating maxRating={5} onRate={handleRating} defaultRating={wig.rating} disabled /></div>
              <Label as='a' color={wig.quantity > 0 ? 'teal':'green'} tag>
                {wig.quantity>0?"Ready To Ship":"Can Be Made"}
              </Label>
              <Row>
                <p className="price-title">CA${price.currentPrice}</p>
                {wig?.originalPrice && (<p className="price-off"><s>CA${wig?.originalPrice}.00</s> ({discountPercentage(wig?.originalPrice, wig?.price)}% off)</p>)}
              </Row>
            </div>

            {/* <ReadMoreAndLess paragraph={wig?.description||''}/> */}
            {/* <p>{wig?.description}</p> */}
          </div>

          <div>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Wig details
                  </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Table bordered hover size="sm">
                      <tbody>
                        {wig.hairOrigin && <tr>
                          <td title="Hair Origin">Hair Origin</td>
                          <td>{wig.hairOrigin}</td>
                        </tr>}
                      
                        <tr>
                          <td title="Length">Length</td>
                          <td>{wig.length.label}</td>
                        </tr>
                        <tr>
                          <td title="Texture">Texture</td>
                          <td>{wig.texture.label}</td>
                        </tr>
                        <tr>
                          <td title="Density">Baby hairs</td>
                          <td>{wig.babyHairs.label}</td>
                        </tr>  
                        <tr>
                          <td title="capType">Cap Type</td>
                          <td>{wig?.capType?.label}</td>
                        </tr>
                        <tr>
                          <td title="Cap Color">Cap Color</td>
                          <td>{wig.capColor.label}</td>
                        </tr>
                        <tr>
                          <td title="Cap Construction">Cap Construction</td>
                          <td>{wig.capConstruction.label}</td>
                        </tr>
                        <tr>
                          <td title="Circumference">Circumference</td>
                          <td>{wig.circumference}</td>
                        </tr>
                        <tr>
                          <td title="Density">Density</td>
                          <td>{wig.density.label}</td>
                        </tr>
                        <tr>
                          <td title="Elastic Bands">Elastic Band</td>
                          <td>{wig.elasticBand.label}</td>
                        </tr>
                        <tr>
                          <td title="Elastic Bands and Combs">Elastic Bands and Combs</td>
                          <td>{wig.elasticBandsAndCombs}</td>
                        </tr>
                        <tr>
                          <td title="Extention Length 1">Extention Length 1</td>
                          <td>{wig.extentionLength1.label}</td>
                        </tr>
                        <tr>
                          <td title="Extention Length 2">Extention Length 2</td>
                          <td>{wig.extentionLength2.label}</td>
                        </tr>
                        <tr>
                          <td title="Extention Length 3">Extention Length 3</td>
                          <td>{wig.extentionLength3.label}</td>
                        </tr>
                        <tr>
                          <td title="Glueless">Glueless</td>
                          <td>{wig.glueless.label}</td>
                        </tr>
                        {/* <tr>
                            <td title="Gluess">Gluess</td>
                            <td>{wig.gluess}</td>
                          </tr> */}
                        <tr>
                          <td title="Wig Combs">Wig Combs</td>
                          <td>{wig.wigCombs.label}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          {wig?.reviews?.length && <div className='mt-4'>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Comments
                  </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <AddReview productId={wig.wigId} />
                    {wig.reviews && <ViewReview reviews={wig.reviews} />}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>}
        </Col>

        <Col sm={5}>
          <div className="title-sm-5">
            <h3>{businessInfo?.businessInfo?.businessName}</h3>
            <Icon name="map marker" />
            <span className="small-text underline-text">{businessInfo?.businessInfo?.businessAddress.city}</span>
            <br />

            <h1>{wig.name}</h1>
            {/* <h2>Ready Made</h2> */}
            <Row>
              <p className="price-title mb-0">CA${price.currentPrice}</p>
              {wig?.originalPrice && (<p className="price-off"><s>CA${wig?.originalPrice}.00</s> ({discountPercentage(wig?.originalPrice, wig?.price)}% off)</p>)}
            </Row>
            <div className='mb-2' > 
            <Rating maxRating={5} onRate={handleRating} defaultRating={wig.rating} size='large' disabled />
            </div>
            <Label as='a' color={wig.quantity > 0 ? 'teal':'green'} tag>
                {wig.quantity>0?"Ready To Ship":"Can Be Made"}
              </Label>

          </div>
          <div className="customize-box">
            <h3>You can customize:</h3>
            {wig && <Formik
              innerRef={itemFormRef}
              initialValues={wig}
              onSubmit={(values) => {
                console.log('data has been set', values)
                console.log('usercart', userCart)
                console.log('userId', userId)
                var tempCart = _.cloneDeep(userCart);
                let doc = firestoreService.getNewCartId(userId)  // get new cart id
                tempCart.push({ ...values, finalPrice: price.currentPrice, cartId: doc.id, displayInCart: true, timeStamp: moment().unix() })
                console.log('shot', tempCart)
                //item the item to the cart for purchase
                dispatch(updateCart(tempCart))

                if (values.installation) {
                  dispatch(updateCartStylistInfo({ businessInfo: businessInfo, installationOptions: installationOptions }))
                  dispatch(updateSelectedInstallation(values.installation))
                }

                tempCart.map(item => {
                  firestore.collection('users/' + userId + '/cart').doc(item.cartId).set(item);
                })
              }}
            >
              {props => (<Form>

                {wig.customizable?.color.length > 1 && (<><Form.Label>Color</Form.Label>
                  <Select
                    options={wig.customizable?.color}
                    value={props.values?.color}
                    onChange={(value) => {


                      props.setFieldValue('color', { ...value, valueUpdated: checkValueUpdated('color', value) })
                      updatePrice('color', value)
                    }} />

                </>)}
                {wig.customizable?.length.length > 1 && (<><Form.Label>Length</Form.Label>
                  <Select
                    options={wig.customizable?.length}
                    value={props.values?.length}
                    onChange={(value) => {
                      props.setFieldValue('length', { ...value, valueUpdated: checkValueUpdated('length', value) })
                      updatePrice('length', value)
                    }} />

                </>)}
                {wig.customizable?.rootColors?.length > 1 && (<><Form.Label>Roots Color</Form.Label>
                  <Select
                    options={wig.customizable?.rootColor}
                    value={props.values?.rootColor}
                    onChange={(value) => {
                      props.setFieldValue('rootColor', { ...value, valueUpdated: checkValueUpdated('rootColor', value) })
                      updatePrice('rootColor', value)

                    }}
                  /> </>)}
                {wig.customizable?.capConstruction?.length > 1 && (<><Form.Label>Cap Construction</Form.Label>
                  <Select
                    options={wig.customizable?.capConstruction}
                    value={props.values?.capConstruction}
                    onChange={(value) => {
                      props.setFieldValue('capConstruction', { ...value, valueUpdated: checkValueUpdated('capConstruction', value) })
                      updatePrice('capConstruction', value)
                    }}
                  /></>)}
                {wig.customizable?.texture?.length > 1 && (<><Form.Label>Texture</Form.Label>
                  <Select options={wig.customizable?.texture}
                    value={props.values?.texture}
                    onChange={(value) => {
                      props.setFieldValue('texture', { ...value, valueUpdated: checkValueUpdated('texture', value) })
                      updatePrice('texture', value)
                    }}
                  />
                </>)}
                {wig.customizable?.density?.length > 1 && (<><Form.Label>Density</Form.Label>
                  <Select
                    options={wig.customizable?.density}
                    value={props.values?.density}
                    onChange={(value) => {
                      props.setFieldValue('density', { ...value, valueUpdated: checkValueUpdated('density', value) })
                      updatePrice('density', value)
                    }}
                  />
                </>)}
                {/* {wig.customizable?.hairOrigin  && (<><Form.Label>Hair Origin</Form.Label>
              <Select options={wig.customizable?.hairOrigin}  
              value={wig.customizable?.hairOrigin[wig.customizable?.hairOrigin.findIndex(data => data.value === wig.hairOrigin)||0]} />
              </>)} */}
                {wig.customizable?.elasticBand?.length > 1 && (<><Form.Label>Elastic Bands</Form.Label>
                  <Select
                    options={wig.customizable?.elasticBand}
                    value={props.values?.elasticBand}
                    onChange={(value) => {
                      props.setFieldValue('elasticBand', { ...value, valueUpdated: checkValueUpdated('elasticBand', value) })
                      updatePrice('elasticBand', value)

                    }}
                  /></>)}
                {wig.customizable?.babyHairs?.length > 1 && (<><Form.Label>Baby Hairs</Form.Label>
                  <Select
                    options={wig.customizable?.babyHairs}
                    value={props.values?.babyHairs}
                    onChange={(value) => {
                      props.setFieldValue('babyHairs', { ...value, valueUpdated: checkValueUpdated('babyHairs', value) })
                      updatePrice('babyHairs', value)


                    }}
                  /></>)}
                {wig.customizable?.capColor?.length > 1 && (<><Form.Label>Cap Color</Form.Label>
                  <Select options={wig.customizable?.capColor}
                    value={props.values?.capColor} /></>)}


                {installationOptions && <>
                  <hr />
                  <h4>Installation Options</h4>
                  {<Select
                    options={installationOptions}
                    defaultValue={installationOptions[installationOptions.length - 1]}
                    value={props.values?.installation}
                    onChange={(value) => {
                      props.setFieldValue('installation', { ...value, valueUpdated: checkValueUpdated('installation', value) })
                      updatePrice('installation', value)

                    }}
                  />}
                  {props.values?.installation && props.values?.installation.value !== 'none' && <p>{props.values?.installation.description}</p>}

                </>}

              </Form>)}
            </Formik>
            }
            <div className="btn-container">
              <Button variant="primary" onClick={() => {
                itemFormRef.current.handleSubmit();
                history.push('/cart');
                dispatch(updateOrderNotes(''))
              }} >Buy it now</Button>
              {/* <Button variant="secondary" onClick={() => {
                itemFormRef.current.handleSubmit();

              }}>Add to cart</Button> */}
            </div>
          </div>
          <div className="delivery-container">
            <Row className="box">
              <Col>
                <p className="underline-text">Estimated arrival</p>
                <span className="big-text">
                  {earlyDeliveryDate} - {latestDeliveryDate}
                </span>
              </Col>
            </Row>
            <Row className="delivery-wrap">
              <div>
                <img src={hello} />
              </div>
              <hr />
              <div>
                <img src={gift} />
              </div>
            </Row>
            <Row className="delivery-info">
              <div>
                <p>{placedDate}</p>
              </div>
              <div>
                <p>
                  {earlyDeliveryDate} - {latestDeliveryDate}
                </p>
              </div>
            </Row>
            <Row className="delivery-info">
              <div>
                <p className="text-delivery underline-text">Order placed</p>
              </div>
              <div>
                <p className="text-delivery underline-text">Delivered!</p>
              </div>
            </Row>
            <Row className="delivery-info box">
              <div>
                <p className="text-delivery">Cost to ship</p>
              </div>
              <div>
                <p className="text-delivery">Returns & exchanges</p>
              </div>
            </Row>
            <Row className="delivery-info">
              <div>
                {businessInfo?.shipping?.shippingProfile?.shippingCostMethod === "fixedPrice" && <span className="big-text">{'CA$' + parseFloat(businessInfo.shipping?.shippingProfile?.fixedPrice)}</span>}
                {businessInfo?.shipping?.shippingProfile?.shippingCostMethod === "pricedFreeShipping" && <span className="big-text">{parseFloat(businessInfo.shipping?.shippingProfile?.freeShippingPrice) < parseFloat(price.currentPrice) ? 'CA$' + parseFloat(price.currentPrice) : "Free Shipping"}</span>}
                {businessInfo?.shipping?.shippingProfile?.shippingCostMethod === "freeShipping" && <span className="big-text">Free Shipping</span>}
              </div>
              <div>
                <span className="big-text">Not accepted</span>
              </div>
            </Row>
            <Row className="delivery-info box">
              {/* <div>
                <span className="small-text">Ship from Canada</span>
              </div> */}
            </Row>
          </div>
          <div className="shop-owner">
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Meet your wig maker
                  </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <div className="box1">
                        <Image src={businessInfo?.businessInfo?.businessLogo} />
                      </div>
                      <div className="box2">
                        <Card.Title>{businessInfo?.businessInfo?.businessName}</Card.Title>
                        <Card.Text>Owner of <Card.Link href={"/view-wigmaker-page/" + businessInfo?.businessInfo?.businessId}> {businessInfo?.businessInfo?.businessName}</Card.Link></Card.Text>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="shop-policies">
            {/* <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Shop Policies
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Card.Title>Returns & exchanges</Card.Title>
                      <Card.Text>{wig?.policies.shipping}</Card.Text>
                    </Card.Body>
                    
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}
            <Accordion className='mt-4' defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Shipping Policy
                  </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {/* <Card.Title>Shipping</Card.Title> */}
                    {/* <Card.Text>{businessInfo?.policies?.shippingPolicy}</Card.Text> */}
                    <ReadMoreAndLess paragraph={businessInfo?.policies?.shippingPolicy || ''} />
                  </Card.Body>

                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion className='mt-4' defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Return & Exchange Policy
                  </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {/* <Card.Title>Returns & exchanges</Card.Title> */}
                    <ReadMoreAndLess paragraph={businessInfo?.policies?.returnPolicy || ''} />
                    {/* <Card.Text>{businessInfo?.policies?.returnPolicy}</Card.Text> */}
                  </Card.Body>

                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          {/* <div className="deliver-box">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Deliver to Canada
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Select options={countryOptions}/>
                      <Select options={provinceOptions} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div> */}

          {/* <div className="shop-owner">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Meet your wig maker
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row>
                        <div className="box1">
                          <Image src={businessInfo?.businessImage} />
                        </div>
                        <div className="box2">
                          <Card.Title>{businessInfo?.businessName}</Card.Title>
                          <Card.Text>Owner of <Card.Link href="#"> {businessInfo?.businessName}</Card.Link></Card.Text>
                        </div>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div> */}
        </Col>
      </Row>
      }
    </div>
  );
}
export default ItemComponent;

