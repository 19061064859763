import React, { useState, useEffect } from "react";
import "./StoreComponent.css";
// import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Nav from "react-bootstrap/Nav"; 
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";


import {  Divider, Container, Icon } from "semantic-ui-react";

import staySafe from "../../../assets/staySafe.jpg";
import autumn from "../../../assets/autumn-goodman-BKhFTUd8PKc-unsplash.jpg";

import CustomWigDetails from "./CustomWigDetails/CustomWigDetails.js";
// import ProductListComponent from "./ProductList/ProductListComponent.js";
import ShippingRulesComponent from "./ShippingRules/ShippingRulesComponent.js";
import PremadeWigs from "./PremadeWigs/PremadeWigs";
import WigListing from "../../WigListing/WigListing"
import { firestore, storage } from "../../../services/firebase.js";
import firestoreService from "../../../services/firestoreService";
import { Rating } from 'semantic-ui-react'

import moment from 'moment'
import { Button } from "react-bootstrap";

import ReadMoreAndLess from '../../shared/ReadMoreAndLess'
import { SellerServices } from "./SellerServices/SellerServices";


function StoreComponent(props) {
  const [index, setIndex] = useState(0);
  const [wigCreator, setWigCreator] = useState();
  const [wigs, setWigs] = useState();

  const prop = { ...props };


  function handleSellerInfo(data) {
    setWigCreator(data)
  }

  useEffect(() => {
    if (!props?.location?.state.creator && props.id) {
      // firestore.collection("/wigCreators/" + props.id + "/public")
      //   .doc("businessInformation")
      //   .onSnapshot((doc) => {
      //     const businessInfo = doc.data();
      //     setWigCreator(businessInfo);
      //   });
      firestoreService.getSellerInfo(props.id, handleSellerInfo)


      firestore.collection("/wigs/").where('wigCreatorId', '==', props.id).where('isListed', '==' , true).get().then(data => {
        var wigData = [];
        data.docs.forEach(wig => {
          wigData.push(wig.data())
        })
        setWigs(wigData)
      })

    }else{
      console.log('cant disply', props?.location?.state.creator )
    }
  }, [])

  useEffect(() => {

  }, [])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {wigCreator && (
        <div className="store-main">
          <div className="banner">
            {/* <img src={wigCreator.businessImages[0].originalUrl} /> */}
             <img src={wigCreator.businessInfo.businessImage} />
          </div>

          <div className="businessInformation">
            <div className="businessInfoTop">
              <div className="businessImageAndLocation">
                <Image src={wigCreator?.businessInfo.businessLogo} roundedCircle />
                {/* <span className="spanText"><Icon name="map marker" />{wigCreator?.businessInfo?.businessAddress?.city}, {wigCreator?.businessInfo?.businessAddress?.country}</span> */}
              </div>

              <div className="businessName">
                <div>
                  <h1> {wigCreator?.businessInfo.businessName}</h1>{" "}
                </div>
                <span className="spanText">
                  <Icon name="map marker" />
                  {wigCreator?.businessInfo?.businessAddress?.city},{" "}
                  {wigCreator?.businessInfo?.businessAddress?.country}
                </span>
                <div>
                  <Rating
                    maxRating={5}
                    defaultRating={wigCreator?.businessInfo?.rating}
                    disabled
                    size="large"
                  />{" "}
                  (30)
                </div>
              </div>
              <div className="businessOwnerImageAndName">
                <div class="image-cropper">
                  <Image src={wigCreator?.businessInfo?.personalImage} />
                </div>
                <span className="spanText">
                  {wigCreator?.businessInfo?.personalName}
                </span>
              </div>
            </div>
            <div>
              <Col sm={12}>
                <hr />
                <h3>Announcements</h3>
                <h6 className="    mb-2">
                  Updated On:{" "}
                  {moment
                    .utc(wigCreator.businessInfo?.announcements?.modifedAt)
                    .format("LL")}
                </h6>
                <ReadMoreAndLess
                  paragraph={wigCreator.businessInfo?.announcements?.text}
                />
                {/* <p>{wigCreator.businessInfo?.announcements?.text}</p> */}
                <hr />
              </Col>
            </div>
          </div>

          <div className="businessInformationBottom">
            <Row>
              <Col sm={8}>
                <SellerServices/>
              </Col>
              {wigs && (
                <Col sm={8}>
                  <PremadeWigs wigs={wigs} {...props} />
                </Col>
              )}
              <div className="businessOwnerImageAndNameMobile">
                <div class="image-cropper">
                  <Image src={wigCreator?.businessInfo.personalImage} />
                </div>
                <span className="spanText">
                {wigCreator?.businessInfo?.personalName}
                </span>
              </div>
              
              {wigCreator && (
                <Col sm={4}>
                  <ShippingRulesComponent wigCreator={wigCreator} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default StoreComponent;

