import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import reducers from './reducers'

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware]

export default configureStore({
    reducer:reducers,
    middleware:middlewares,
})

sagaMiddleware.run(sagas)