import React, {useState} from 'react';
import "./SidebarComponent.css";

import {Link } from "react-router-dom";
import { Item} from 'semantic-ui-react';

import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";



const Submenu = ({item}) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
      <div className="submenu-box">
        <Nav.Link as={Link} to={item.path} onClick={item.subNav && showSubnav}>
          <div>{item.icon}</div>
          <div>
            {item.title}
          </div>
          <div>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </Nav.Link>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <div className="subnav-box">
                <Nav.Link as={Link} to={item.path} key={index}>
                  <div>{item.icon}</div>
                  <div>{item.title}</div>
                </Nav.Link>
              </div>
            );
          })}
      </div>
    );
}

export default Submenu;