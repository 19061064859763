import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment'
import { updateDisplayHeaderAndFooter, updateDis } from '../../redux/user/userSlice'
import { useSelector, useDispatch } from 'react-redux';
import './aboutUs.css'
import FashionGirlsImg from "../../assets/FashionGirlsImg.svg";
import workstationImg from "../../assets/workstationImg.svg";
import plantImg from "../../assets/Plant.svg";

import WhyUsSection from '../WhyUsSection/WhyUsSection'

const AboutUs = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateDisplayHeaderAndFooter({ header: false, footer: true }))

    }, [])


    //Todo format, also add uploaded image
    return (
            <div className='aboutContainer'>
                <div className='title'>
                    <Link  to={'/home'}> 
                      <h2>Yornel</h2> 
                    </Link>
                </div>
                <div className='topImg' >
                    <img src={FashionGirlsImg}/>
                </div>
                <div className='topMessage'>
                    <h2>
                    The first marketplace of independent wig artist                    
                    </h2>
                </div>
                <div className="aboutUSBuyerContainer">
                    <div className="aboutUSBuyerText" >
                        <h1>About Yornel</h1>
                        <p>Yornel is the first and only online marketplace dedicated to buying and selling professionally made wigs by independent wig artists. We are growing a vibrant community of hair enthusiasts around the world. By focusing on quality, price transparency,hair-focused eCommerce tools, an amazing hair-savvy customer support team, and more. Yornel has created an online destination where buyers and sellers can securely purchase their wigs in a open and fair market.</p>
                    </div>
                    <div className="aboutUSBuyerImg" >
                        <img src={workstationImg}/>
                    </div>
                </div>

                <div className="missionTitle">
                    <h2>OUR MISSION IS TO</h2>
                    <h2>Give women control over their hair</h2>
                </div>
                
                <div className="journeyContainer">
                    <div className="journeyText">
                        <h2>Our Journey</h2>
                        <p>Yornel launched in 2020 amid the pandemic as an online marketplace, built for those who wanted to buy and sell quality handmade wigs. We saw that many talented wig artists in Canada who were making amazing wigs not getting the recognition that they deserve. While factory wig sellers use predatory tactics to sell low-quality wigs, with review manipulation, and taking hostage of customers money to get 5 stars reviews. Since then we have aimed to make the playing field fair, equal, and ethical for both sellers and buyers.</p>
                    </div>
                </div>

                <div className="bottomBuyerImg">
                    <img src={plantImg}/>
                </div>
            </div>
    )
}

export default AboutUs;