import {put,take,takeEvery,call,fork,all} from 'redux-saga/effects';
import {updateLoggedIn,updateOrders,updateAdminInfo} from './adminSlice'
//services

function* fetchOrders(action){

}


function* fetchAdminInfo(action){

}

export function* adminSaga(){
    // yield takeEvery(updateLoggedIn,fetchOrders);
    // yield takeEvery(updateOrders);
}