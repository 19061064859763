import React from "react";
import "./StyleCategoriesSection.css";
import FrontalClosureComponent from "./FrontalClosureComponent";
import TextureColorTypeComponent from "./TextureColorTypeComponent";



function StyleCategoriesSection(){ 

    return (
      <div className="styles-content">
        <h2>Styles</h2>
        <div className="content-boxes">
            <div className="frontal-closure">
                <FrontalClosureComponent />
            </div>
            <div className="texture-color">
                <TextureColorTypeComponent />
            </div>
        </div>
      </div>
    );
}

export default StyleCategoriesSection;