import React, { useEffect, useState } from "react";
import './PricingComponent.css';
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";


import { Header, Image, Table, Icon } from "semantic-ui-react";

function PricingComponent() {
  return (
    <div className="pricing-box">
        <h1>Pricing</h1>
        <Container className="table-box">
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <Header.Content>
                    Standard
                    <Header.Subheader>$30 / month</Header.Subheader>
                  </Header.Content>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      Online booking 24/7 for your customers
                      <Header.Subheader>Human Resources</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon color="green" name="checkmark" size="large" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      Management Tools
                      <Header.Subheader>Fabric Design</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon color="green" name="checkmark" size="large" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      Social media integrations
                      <Header.Subheader>
                        Facebook, Instagram and Google
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon color="green" name="checkmark" size="large" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      Customer support
                      <Header.Subheader>
                        Helpline, e-mail, chat
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon color="green" name="checkmark" size="large" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>
                      Customer notification and reminder SMS
                      {/* <Header.Subheader>Helpline, e-mail, chat</Header.Subheader> */}
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Icon color="green" name="checkmark" size="large" />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Button variant="success" size="md" block>
            Start my business
          </Button>
        </Container>
        <Container className="faq-box">
          <h3>Frequantly asked questions</h3>
          <Row>
            <Col>
            <h5>How does the free trial work?</h5>
            <p>
              We give you the opportunity to try all of the features and functionalities we have to offer risk-free. No payment information is required to create a trial account, and you can always see how much time you have left on your trial by going to Choose your Subscription
            </p>
            </Col>
            <Col>
              <h5>What happens when my trial ends?</h5>
              <p>
                Just before your trial ends, you will receive an email notification. To continue using Yornel, you be given several payment options to set up a subscription. After the trial period, accounts without a subscription are deactivated by the system, but you can always reactivate your account by subscribing.
            </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>How can I pay for Yornel?</h5>
              <p>
                You can pay for Yornel using a credit card or Paypal.
            </p>
            </Col>
            <Col>
              <h5>Need more help before signing up?</h5>
              <p>
                Please feel free to contact us at help@Yornel.com or +1206-735-3553. We’re always here to help you!
            </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>What happens when I cancel?</h5>
              <p>
               Your account will be disabled and you will no longer be charged.
              </p>
            </Col>
            <Col>
              <h5>Need more help before signing up?</h5>
              <p>
                Please feel free to contact us at help@Yornel.com or +1206-735-3553. We’re always here to help you!
              </p>
            </Col>
          </Row>
        </Container>
    </div>
  );
}
export default PricingComponent;