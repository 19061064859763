import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, useHistory, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
// import "semantic-ui-css/semantic.min.css";
// import WigMakerView from './WigMakerView/WigMakerView';
import Navbar from 'react-bootstrap/Navbar'
// import OrderPageEdit from "./WigMakerView/OrderPageEdit/OrderPageEdit.js";
// import './assets/css/fonts.css';
import BusinessComponent from "./BusinessComponent/BusinessComponent.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase, { firestore, auth } from './services/firebase';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserId, updateUserInfo, updateControlValues, updateLoggedIn } from './redux/user/userSlice'
import firestoreService from './services/firestoreService'
import 'reactjs-popup/dist/index.css';


function App() {
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {


    //check the state on user who is logged in and then store it in redux
    auth.onAuthStateChanged(user => {
      if (!user) {
        auth.signInAnonymously().then(data => {

          dispatch(updateUserId(data.user.uid))
          console.log('sign')
          // firestore.collection('wigCreators/' + data.user.uid + '/businessInformation').doc('public').get().then((data) => {
          //   console.log('mush', data.data())
          //   dispatch(updateUserInfo(data.data()))
          // })

        })
      } else {
        
        dispatch(updateUserId(user.uid))
        if (!user.isAnonymous) {
          console.log('checking if user is logged in?', user)

          dispatch(updateLoggedIn(true));
        }
        firestoreService.getUserInfo(user.uid, setUserInfo)
      }
    })

    firestoreService.getControlValues(setControlValue)


  }, [])

  function setControlValue(data) {
    dispatch(updateControlValues(data))
  }

  function setUserInfo(data) {
    dispatch(updateUserInfo(data))
  }



  return (
    <>
      <div className="App">
        <BusinessComponent />
      </div>
      <ToastContainer />
    </>
  );


}

export default App;
