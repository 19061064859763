import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import qs from "qs"
import "./WigListing.css";
import { firestore, storage } from '../../services/firebase';
import WigCard from './WigCard'
import { updateLoading } from '../../redux/user/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import cannotFind from '../../assets/cannotFind.svg'


function WigListing(props) {
  const [wigs, setWigs] = useState([]);
  const [search, setSearch] = useState('All');
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)


  useEffect(() => {
    var tempSearch = props?.match?.params.filter ? props?.match?.params.filter : props.filter;

    // // qs.parse(this.props.location.search)
    // console.log('qs', props?.match?.params.filter)

    // console.log('check', props.wigs)


    if (!loading) {
      setLoading(true)
    }

    setSearch(tempSearch)
    if (!props.wigs) {
      if (tempSearch !== 'all' && tempSearch) {
        firestore.collection('/wigs').where('designation', '==', tempSearch).limit(props.limit || 10).get().then((querySnapshot) => {
          var temp = []
          if (!querySnapshot.empty) {
            temp = querySnapshot.docs.map(data => data.data())
            setWigs(temp)
          }else{
            setWigs(temp)
          }
          setLoading(false)
        })
      } else {

        firestore.collection('/wigs').limit(props.limit || 10).get().then((querySnapshot) => {
          var temp = []
          if (!querySnapshot.empty) {
            temp = querySnapshot.docs.map(data => data.data())
            setWigs(temp)
          }
          setLoading(false)
        })
      }
    } else {
      setWigs(props.wigs)
    }
  }, [ props?.match?.params.filter])

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="wigseller-box">
      {!props.landing && <h1 style={{textAlign:'center'}}>{capitalizeFirstLetter(search || '')} Wigs</h1>}
      {!loading&&<Row className={'m-7'}>

        {!!wigs.length&&wigs.map((wig, index) => {

          return <WigCard key={index} wig={wig} index={index} />
        })
        } 

        {!wigs.length&&
        <Col > 
        <img className='center' style ={{width:'300px',}} src={cannotFind} />
        <h3 style={{textAlign:'center'}}>No Wigs Found</h3>
        </Col>
        }
      </Row>}

      {loading&&(<div className="loading-page"><ClipLoader color={'black'} size={35} loading={true} /></div>)}
    </div>
  );

}
export default WigListing;