import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';

function discountPercentage(originalPrice, currentPrice) {
  var percent = currentPrice - originalPrice;
  percent = percent / originalPrice * 100
  return Math.abs(percent);
}



const WigCreatorCard = (props) => {
useEffect(()=>{
console.log('cofune',props?.creator )
},[])

  return (
    <>
      {props?.creator && <>
        {
          // < >
            <Col lg={3} key={props?.index + 'wigListing'} >
              <Link
                to={{
                  pathname: "/view-wigmaker-page/" + props.creator?.businessInfo.businessId,
                  state: { creator: props.creator }
                }}
              >
                <div className="wigcard-img">
                  <img className={'p-0'}  src={props.creator?.personalImage} />
                </div>
                <h3>{props.creator?.businessInfo?.businessName}</h3>
                <Row>
                  <Col lg={4}>
                    <Rating name="read-only" value={5} readOnly />
                  </Col>
                  <Col lg={2}>
                    (15)
                </Col>
                </Row>

                {/* <Row>
                  <Col lg={2}> <h3 className={'m-0'} style={{ color: 'black', fontWeight: 'bold' }}>CA${props?.wig?.price}</h3></Col>
                  {props.wig?.originalPrice && <Col lg={6}> <h6 style={{ fontSize: '12px' }}><s>CA${props.wig?.originalPrice}.00</s>({discountPercentage(props.wig?.originalPrice, props.wig?.price)}% off) </h6></Col>}
                </Row> */}
                <h6>{props.creator?.businessInfo?.name}</h6>
              </Link>
            </Col>
          // </>
        }
      </>}
    </>
  )
}

export default WigCreatorCard;