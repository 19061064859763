import {put,take,takeEvery,call,fork,all, select} from 'redux-saga/effects';
import {updateCart,getUserId} from './userSlice';
import {firestore} from '../../services/firebase';
import { useSelector, useDispatch } from 'react-redux';

//services
// const userId = useSelector((state) => state.User.userId)
function* updateFirestoreCart(action){
let userId = yield select(getUserId);


console.log('violent incidents',action)
console.log('good times',userId.User)
// firestore.collection('users/'+userId+'/cart').doc().set(action)
}


function* fetchUserInfo(action){

}

export function* userSaga(){
    yield takeEvery(updateCart,updateFirestoreCart);
    // yield takeEvery(updateOrders);
}