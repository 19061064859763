import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment'
import { updateDisplayHeaderAndFooter } from '../../redux/user/userSlice'
import { useSelector, useDispatch } from 'react-redux';
import roadMapImg from "../../assets/Roadmapping.svg";
import Finances from "../../assets/Finances.svg";
import Ecommerce from "../../assets/Ecommerce.svg";
import Globe from "../../assets/Globe.svg";
import Energy from "../../assets/Energy.svg";
import Enjoy from "../../assets/Enjoy.svg";
import Check from "../../assets/Check.svg";
import { Icon } from "semantic-ui-react";

import './aboutUs.css'

const AboutUsSeller = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('hahahah', props.reviews)
        dispatch(updateDisplayHeaderAndFooter({ header: false, footer: true }))

    }, [])


    //Todo format, also add uploaded image
    return (
                <div className='aboutContainer'>
                    <div className='title'>
                        <h1 >Yornel</h1>
                    </div>
                    <div className="mainContainer">
                        <div className='mainMessage'>
                            <h1>
                                Reach millions of wig buyers in North America
                            </h1>
                            <p>
                                Quality wigs are already hard to come by, we make them easier to find.
                            </p>
                        </div>
                        <div className='mainImg' >
                            <img src={roadMapImg} />
                        </div>
                    </div>

                    <div className="bussinessDetailsTitle">
                        <h2> Join the hair marketplace where shoppers will come to spend millions each year purchasing directly from a wig entrepreneurs like you.</h2>
                    </div>

                    <div className="howItWorksContainer">
                        <div className="leftSection">
                            <img src={Finances} />
                            <h3>Great Value</h3>
                            <p>
                                List your wigs & services for free - we only take 1% per every transaction.
                            </p>
                        </div>

                        <div className="centerSection">
                            <img src={Ecommerce} />
                            <h3>Powerful Tools</h3>
                            <p>
                                Our tools and services make it easy to manage, promote and grow your business.
                            </p>
                        </div>

                        <div className="rightSection">
                            <img src={Globe} />
                            <h3>Connect With The Market</h3>
                            <p>
                                Compete on a global and local scale. Women all around are looking for quality.
                            </p>
                        </div>
                    </div>

                    <div className="paymentInformation">
                        <div className="infoBody">
                            <div className="infoBodyTitle">
                                <h1>Simple, transparent, secure</h1>
                            </div>
                            <hr />
                            <div className="infoBenefits">
                                <div>
                                    <Icon name="check" />
                                    <p>No additional monthly fees</p><br/>
                                    <Icon name="check" />
                                    <p>Automatic deposits</p>
                                </div>
                                <div>
                                    <Icon name="check" />
                                    <p>Secure transactions</p><br/>
                                    <Icon name="check" />
                                    <p>Seller protection</p>
                                </div>
                            </div>
                            <hr />

                            <div className="infoFees">
                                <div className="iconFees">
                                    <Icon name="list" />
                                </div>
                                <div className="textFees">
                                    <h3 className='mb-0'>List products & services for free </h3>
                                    <p>Listings will always be active for buyers</p>
                                </div>
                            </div>

                            <div className="infoFees">
                                <div className="iconFees">
                                    <Icon name="credit card outline" />
                                </div>
                                <div className="textFees">
                                    <h3 className='mb-0'>1% Transaction fee, 2.9% + CA$0.30 payment processing fee </h3>
                                    <p>When you sell an item or service, there’s a small commission and standard payment processing fee</p>
                                </div>
                            </div>
                        </div>

                        <div className='securePaymentProvider'>
                            <div>
                                <p>We process payments through Stripe,  which is a secure SSL-encrypted platform,  that have security specialists and fraud detection systems to protect you and your buyers 24/7</p>
                            </div>
                        </div>
                    </div>

                    <div className='infoBottomSection'>
                        <div className='leftBottomSection'>
                            <div>
                                <h2>Simple, powerful tools</h2>
                                <p>Spend less time managing your shop and more time on the fun stuff.</p>
                            </div>

                            <div>
                                <h4>Accept Appointments </h4>
                                <p>Accept wig installation appointments, just set your availability and clients can book you anytime.</p>
                            </div>
                            <div>
                                <h4>Accept BYOB(Bring your own bundles) orders </h4>
                                <p>Clients can easily send bundles or drop off. Without you needing to interact with them at all.</p>
                            </div>
                            <div>
                                <h4>Ready-to-ship, Can-be-made, Full Custom </h4>
                                <p>Sell all version of wigs, we got the tools to handle them all. And so much more.</p>
                            </div>
                        </div>

                        <div className='leftBottomSectionImg'>
                            <img src={Energy} />
                        </div>
                        
                        <div className='rightBottomSectionImg'>
                            <img src={Enjoy} />
                        </div>
                        <div className='rightBottomSection'>
                            <div>
                                <h2>Reach a world of shoppers</h2>
                                <p>Build your brand with powerful marketing tools.</p>
                            </div>

                            <div>
                                <h4>Grow your following </h4>
                                <p>We make it easy for you to be found beyond instagram.</p>
                            </div>
                            <div>
                                <h4>Boost your visibility  </h4>
                                <p>Reach millions of shoppers on and off Yornel. With our advertising your listings are more visible in when searched. We will advertise your listings on sites like Google, Facebook, Instagram, and Pinterest all for free.</p>
                            </div>
                            <div>
                                <h4>Attract new customers</h4>
                                <p>Grow your local customer base. they are right around the corner.</p>
                            </div>
                        </div>      
                    </div>
                </div>
    )
}

export default AboutUsSeller;