import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

import { RiListOrdered } from "react-icons/ri";

export const SidebarData = [
  // {
  //   title: "Home",
  //   path: "/home",
  //   icon: <AiIcons.AiFillHome />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: "Users",
  //       path: "/home/users",
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //     {
  //       title: "Revenue",
  //       path: "/home/revenue",
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //   ],
  // },
  // {
  //   title: "Reports",
  //   path: "/reports",
  //   icon: <IoIcons.IoIosPaper />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: "Reports 1",
  //       path: "/reports/reports1",
  //       icon: <IoIcons.IoIosPaper />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Reports 2",
  //       path: "/reports/reports2",
  //       icon: <IoIcons.IoIosPaper />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Reports 3",
  //       path: "/reports/reports3",
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //   ],
  // },
  {
    title: "Purchases",
    path: "/clientDashboard/purchases",
    icon: <FaIcons.FaClipboardList />,

  },
  // {
  //   title: "Orders",
  //   path: "/orders",
  //   icon: <FaIcons.FaClipboardList />,

  // }, 
  {
    title: "Public Profile",
    path: "/clientDashboard/profile",
    icon: <AiIcons.AiFillHome />,
  },
  // {
  //   title: "Wigs",
  //   path: "/products",
  //   icon: <FaIcons.FaCut />,
  // },
  {
    title: "Settings",
    path: "/policy",
    icon: <FaIcons.FaGavel />,
  },
  // {
  //   title: "Payment",
  //   path: "/payment",
  //   icon: <FaIcons.FaCreditCard />,
  // },
  // {
  //   title: "Shipping",
  //   path: "/shipping",
  //   icon: <FaIcons.FaTruckMoving />,
  // },
  // {
  //   title: "Support",
  //   path: "/support",
  //   icon: <IoIcons.IoMdHelpCircle />,
  // },
];

export default SidebarData;