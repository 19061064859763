import React, { useState } from 'react';
import "./SidebarComponent.css";

import { useMediaPredicate } from "react-media-hook";
import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";


import SidebarData from "./SidebarData.js";
import Submenu from "./Submenu.js";



const SidebarComponent = () => {
    const biggerThan575 = useMediaPredicate("(min-width: 575.98px)");
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);


    return (
      <div className="sidebar-box">
        {!sidebar && (
          <Navbar>
            <Nav to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </Nav>
          </Navbar>
        )}
        {sidebar && (
          <Navbar>
            <Nav to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </Nav>
            {SidebarData.map((item, index) => {
              return <Submenu item={item} key={index} />;
            })}
          </Navbar>
        )}

        {biggerThan575 && (
          <Navbar>
            {SidebarData.map((item, index) => {
              return <Submenu item={item} key={index} />;
            })}
          </Navbar>
        )}
        
      </div>
    );
}

export default SidebarComponent;


