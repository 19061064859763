import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../../redux/user/userSlice';
import { firestore } from '../../../services/firebase'
import firestoreService from '../../../services/firestoreService'
import _ from 'lodash';
import CartItemComponent from '../../Cart/CartItemComponent'
import WigDescriptionComponent from '../../shared/WigDescriptionComponent';
import moment from 'moment'
import { FaStripe, FaCcVisa, FaCcMastercard, FaCcJcb, FaCcAmex } from 'react-icons/fa';
import { ReactComponent as Stripe } from '../../../assets/stripe.svg'
import Popup from 'reactjs-popup';
import ClipLoader from "react-spinners/ClipLoader";







function StripePaymentComponent(props) {
    const userCart = useSelector((state) => state.User.cart)
    const userId = useSelector((state) => state.User.userId);
    const orderId = firestoreService.getNewOrderId();
    const stylistInfo = useSelector((state) => state.User.cartStylistInfo);
    const orderNotes = useSelector((state) => state.User.orderNotes)
    const [finalTotal, setFinalTotal] = useState(0);
    const [itemsTotal, setItemsTotal] = useState(0)
    const [loading, setLoading] = useState(false);



       //create a whole order obj to manage 
       function handleOrder() {
        console.log('what is userId in review',userId)

        const deliveryData = props.deliveryData
        const wigCreatorInfo = props.wigCreatorInfo
        const cart = props.cart;
        // const orderId = firestoreService.getNewOrderId()
        const modifiedLog = { userId: userId, timeStamp: moment.utc().unix(), action: 'ORDER_CREATION_INTENT_TO_PAY' }
        const orderDetails = {
            items: cart,
            orderId: orderId,
            wigCreatorId: cart[0].wigCreatorId, //wigCreatorInfo.businessInformation.businessId, //temp right now no bussiness account setup in place
            clientId: userId,
            // clientEmail: email,
            // clientPhoneNumber: phoneNumber,
            deliveryMethod: deliveryData,
            modifiedAt: moment.utc().unix(),
            createdAt: moment.utc().unix(),
            depositPaid: false,
            paymentStatus: 'UNPAID',
            orderStatus: 'WAITING_FOR_PAYMENT',
            uiOrderTotalAmount: finalTotal,
            // currentUrl:,
            discounts: 0,
            discountCode: null,
            promotion: 'NONE',
            notes: orderNotes,
            orderLog: [modifiedLog],
            orderFullfilled: false,
            appointment: {
                appointmentStatus: 'NONE',
                hasAppointment: false,
                appointmentType: 'INSTALL',
                startTime: null,
                approvedByStylist: false,
                approvedByClient: false,
                clientContacted: false,
                location: 'STYLIST',
                notes: '',
                modifiedAt: moment.utc().unix()
            }
        }

        console.log('chekcingf final oreder', orderDetails);
        console.log('wigcreator', wigCreatorInfo)
        firestoreService.createOrder(orderId, orderDetails).then(data => {
            console.log('we successfully created an order', data, orderId)
            firestoreService.payWithStripe(orderId).then(data => {
                const url = data.data;
                window.open(url, "_self")
            }).catch(err => {
                console.log('Err has occured', err)
                setLoading(false)

            })
        });

    }

return(
    <>
    
    <div >
                    <button disabled={loading} onClick={() => {
                       setLoading(true)
                       handleOrder();
                        

                    }} className='formatButton' >
                        Pay Now
                    </button>
                    <Col>
                        <div style={{ width: '20%', margin: '0 auto', marginBottom: '15px' }} >
                            <Stripe />
                        </div>
                      
                    </Col>

                </div>
    
    </>
)

}

export default StripePaymentComponent