import React, { useEffect, useState } from "react";
import "./ShippingRulesComponent.css";
import { Tab } from "semantic-ui-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaInstagram, FaCheckCircle, FaTimesCircle, FaMoneyCheckAlt } from 'react-icons/fa';
import _ from 'lodash'
import { Button } from "react-bootstrap";
import ReadMoreAndLess from '../../../shared/ReadMoreAndLess'



function ShippingRulesComponent(props) {
  useEffect(() => {
    console.log(props.wigCreator)
  }, [])
  return (
    <div className="rules-box">

      <Row>
        <Col>
          <h6 className="rules-header">About me</h6>
          <ReadMoreAndLess paragraph={props.wigCreator.businessInfo?.aboutMe} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="rules-header">Delivery Methods</h6>
          {props?.wigCreator?.shipping?.shippingProfile?.shippingCostMethod === "pricedFreeShipping"&&<div><FaCheckCircle color='green' /> Shipping - ${props?.wigCreator?.shipping?.shippingProfile?.fixedPrice} (Free for orders over ${props?.wigCreator?.shipping?.shippingProfile?.freeShippingPrice})  </div>}
          {(props?.wigCreator?.shipping?.shippingProfile?.shippingCostMethod === "freeShipping" || props?.wigCreator?.shipping?.shippingProfile?.shippingCostMethod === null) &&<div><FaCheckCircle color='green' /> Shipping - Free  </div>}
          {props?.wigCreator?.shipping?.shippingProfile?.shippingCostMethod === "fixedPrice"&&<div><FaCheckCircle color='green' /> Shipping - ${props?.wigCreator?.shipping?.shippingProfile?.fixedPrice}  </div>}
          <div>{props?.wigCreator?.shipping?.wigPickUp?.iAcceptWigPickups ? <FaCheckCircle color='green' /> : <FaTimesCircle color='red' />} Pickups </div>
        </Col>
      </Row>
      <Row>
        {props?.wigCreator?.services?.acceptingAppointments ? <Col>
          <h6 className="rules-header">Services</h6>
          <div><FaMoneyCheckAlt color='green' /> Deposit - {!props?.wigCreator?.services?.minimumDeposit?.requried ? '$' + props?.wigCreator?.services?.minimumDeposit?.amount : 'No Deposit'}  </div>
          <div>{!_.isEmpty(props?.wigCreator?.services?.wigInstallation) ? <FaCheckCircle color='green' /> : <FaTimesCircle color='red' />} Wig Installation</div>

        </Col> : <Col><h6>Not Accepting Any Appointments At This Time</h6> </Col>}
      </Row>

      {props?.wigCreator?.policies?.shippingPolicy && <Row>
        <Col>
          <h6 className="rules-header">Shipping Policy</h6>
          <ReadMoreAndLess paragraph={props.wigCreator.policies?.shippingPolicy} />
        </Col>
      </Row>}
      {props?.wigCreator?.policies?.returnAndExchange && <Row>
        <Col >
          <h6  className="rules-header">Returns & Exchanges</h6>
          <ReadMoreAndLess paragraph={props.wigCreator.policies?.returnAndExchange} />
        </Col>
      </Row>}
      {props?.wigCreator?.policies?.damagedAndLostPolicy && <Row>
        <Col>
          <h6  className="rules-header">Damaged & Lost</h6>
          <ReadMoreAndLess paragraph={props.wigCreator.policies?.damagedAndLostPolicy} />
        </Col>
      </Row>}

      {props?.wigCreator?.faq && <Row>
        <Col >
          <h6  className="rules-header">FAQ</h6>
          <ReadMoreAndLess paragraph={"I create the best wig in Toronto. My 5 years experience bla bla bla"} />
        </Col>
      </Row>}
      {!_.isEmpty(props?.wigCreator?.businessInfo?.socialMedia) && <Row>
        <Col>
          <h6  className="rules-header">Social Media</h6>
          {/* Instagram */}
          <a href={props?.wigCreator?.businessInfo?.socialMedia?.instagramUrl} >
            <FaInstagram size={'2em'} />
          </a>

        </Col>
      </Row>}
    </div>

  );
}

export default ShippingRulesComponent;
