import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from 'react-redux';
import "./CartComponent.css";
import CartItemComponent from './CartItemComponent'
import { Link } from "react-router-dom";
import firebaseService from "../../services/firestoreService"
import { updateCart, updateDisplayHeaderAndFooter,updateOrderNotes,updateAuthModalActive } from '../../redux/user/userSlice';

function CartComponent() {
    const userCart = useSelector((state) => state.User.cart)
    const userId = useSelector((state) => state.User.userId)
    const loggedIn = useSelector((state) => state.User.loggedIn)
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [cart, setCart] = useState(userCart)
    const [notes, setNotes] = useState("");

    useEffect(() => {
        dispatch(updateDisplayHeaderAndFooter({header:true,footer:true})); 
        var tempTotal = 0
        if (userCart.length) {
            console.log('show cart items', userCart)
            setCart(userCart)
            userCart.map(item => { tempTotal = tempTotal + item.finalPrice })
            setTotal(tempTotal)
        } else {
            //checks if user id has been set yet
            if (userId) {
                //gets the current user cart from db if data not in redux. then sets the data
                firebaseService.getCart(userId).then(data => {
                    const cartItems = data.docs.map(items => items.data())//transform the data to get the data from firebase
                    cartItems.map(item => { tempTotal = tempTotal + item.finalPrice })//total up all of the items

                    setCart(cartItems)//set the values into the global cart obj
                    setTotal(tempTotal)
                    dispatch(updateCart(cartItems));
                    // console.log('show whole caert', cartItems)
                })
            }
        }

    }, [userCart, userId])

    

    function handleNote(event) {
        setNotes(event.target.value)
        dispatch( updateOrderNotes(event.target.value))
    }

    return (
        <div className="cart-container">
            {cart.length>0&&<Row>
                <Col>
                    <div className='mt-3' style={{ textAlign: 'center' }}>
                        <h1>Your Cart</h1>
                    </div>
                    {cart.map((item, index) => {
                        // var tempTotal =  total + item.finalPrice;
                        return (
                            <CartItemComponent index={index} item={item} isLastIndex={cart.length === index+1} />
                        )
                    })}
                    <div className='note-header'>
                        <h3>Notes: </h3>
                    </div>

                    <div className='note-box'>
                        <textarea value={notes} onChange={handleNote} rows="4" cols="40" />
                    </div>
                    <hr />
                </Col>
                <Col className='p-2' sm={12}>
                    <Container>
                        <Col>
                            <Row>
                                <Col><b> Item(s) Total:</b></Col>
                                <Col>CA${total}    </Col>

                                <Button
                                    variant='success'
                                    as={Link}
                                    to={{ pathname: "/payment/final" }}
                                    onClick={() => {
                                        dispatch(updateDisplayHeaderAndFooter({header:false,footer:false}));
                                        if(!loggedIn){
                                        dispatch(updateAuthModalActive(true))
                                    }
                                    }} >Checkout</Button>

                            </Row>
                        </Col>
                    </Container>

                </Col>
            </Row>}
            {cart.length === 0&&<h1 className='mt-4' style={{textAlign:'center'}} >No Items In Your Cart</h1>}
        </div>
    )
}

export default CartComponent;