import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { firestore, storage } from '../../services/firebase';
import WigCreatorCard from './WigCreatorCard'
import SellerCard from '../shared/Cards/SellerCard'
import { useSelector, useDispatch } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";


function WigCreatorListing(props) {
  const [wigCreators, setWigCreators] = useState([]);
  const userId = useSelector((state) => state.Admin.userId)
  const [loading,setLoading] = useState(false)
  useEffect(() => {
  

    var creator=[]
    
    if (!loading) {
      setLoading(true)
    }

    firestore.collection('sellers').where('isListed','==',true).get().then(data => {
        data.docs.forEach(data=>{
            creator.push(data.data())
        })
        setLoading(false)
        setWigCreators(creator)
        
    })
    
  }, [])
  return (
    <div className="wigseller-box">
      {!props.landing&&<h1 style={{textAlign:'center'}}>All Wig Artist</h1>}
      {!loading&&<Row className={'m-7'}>
        
        {wigCreators.map((creator, index) => {
          
         return <SellerCard creator={creator} index={index}  />
        })
        }
      </Row>}
      {loading&&<ClipLoader color={'black'} size={35} loading={true} />}
    </div>
  );

}
export default WigCreatorListing;