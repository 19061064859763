import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";

export default function ReadMoreAndLess(props) {


  const [paragraph, setParagraph] = useState('')
  const [readMore,setReadMore] = useState(false)

useEffect(()=>{
  if(props?.paragraph){
  setParagraph(props?.paragraph.substring(0, 100)+' ...')
}
},[props?.paragraph])

  return (
    <>
      <p>{paragraph}</p>
      <span >
      {props.paragraph&&props.paragraph.length>100&&<Button
      variant='link'
      
      onClick={()=>{
        setReadMore(!readMore)

        if(!readMore){
          setParagraph(props.paragraph)
        }else{
          setParagraph(props.paragraph.substring(0, 100) +'...')
        }
      }}
      >{!readMore ? 'Read More': 'Read Less'} </Button>}
      </span>
    </>
    
  )
}