import React from 'react';
import { Component, useState,useEffect } from 'react';
import { Button, Label, Menu } from 'semantic-ui-react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import OrderDetails from "../OrderDetails/OrderDetails.js";
// import OrderListData from "../../../../dummyData/OrderListData.js";
import {firestore} from '../../../../../services/firebase'
import { useSelector, useDispatch } from 'react-redux'

const OrdersList = (props) => {
    const [orderList, setOrderList] = useState();
    // let prop = {...props}
    const userId = useSelector((state) => state.User.userId)

    // console.log(props,"here");

    useEffect(()=>{
      console.log('what is userId in orderlist',userId)
      firestore.collection('orders').where('clientId','==',userId).get().then(data=>{
        var tempDocData = []
        data.docs.map(doc=>{
          tempDocData.push(doc.data())
          console.log('show me all docs',doc.data())
        })
        setOrderList(tempDocData)
      })
    },[])
    

    return (
      <div>
        <a>
          <h1>Purchases</h1>
        </a>
        <Table >
          <Thead>
            <Tr>
              <Th>Order ID</Th>
             
              <Th>Status</Th>
              <Th>Order Placed</Th>
              <Th>Quantity</Th>
              <Th>Total</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>

          <Tbody>
            {orderList&&orderList.map((list,index) => {
              console.log('hit em where it hurts',orderList)
              return (
                <>
                  <Tr key={index}>
                    <Td>{list?.orderId}</Td>
                    
                    <Td>{list?.status}</Td>
                    <Td>{list?.orderPlacedAt}</Td>
                    <Td>{list?.quantity}</Td>
                    <Td>${list?.totalPrice}</Td>
                    <Td>
                      <Button
                        onClick={() => {props.displayComponent(list);
                            // props.indexPass(list?.childKey)
                        }}
                        color="olive"
                      >
                        View
                      </Button>
                    </Td>
                  </Tr>
                </>
              );
            })}           
          </Tbody>
        </Table>
      </div>
    );
}

export default OrdersList;
