import React from 'react';
import {  useState, useEffect } from 'react';

import OrderList from "./OrderList/OrdersList.js";
import OrderDetails from './OrderDetails/OrderDetails.js';
import _ from 'lodash'
const ORDERDETAILS = "ORDERDETAILS";
const ORDERLIST = "ORDERLIST";

const OrdersComponent = (props) => {
    
    const [component, setComponent] = useState(ORDERLIST);
    const [orderID, setOrderId] = useState();
    const [currentItem,setCurrentItem]= useState()
   
    var displayComponent = (data,componentView) => {
        setComponent(componentView); 

      if(!_.isEmpty(data)){
        setCurrentItem(data)
      }
    }  

    
    // useEffect(() => {console.log(props.view, 'rr');
    //     setComponent(props.view)
    // },[])

    return (
      <div>
        {component === ORDERLIST && (
          <OrderList
            displayComponent={(item) => {
              displayComponent(item,ORDERDETAILS)
              
            }}
          />
        )}

        {component === ORDERDETAILS && currentItem && (
          <OrderDetails
            item = {currentItem}
            displayComponent={() => {
              displayComponent(null,ORDERLIST)
            }}
            // indexPass={orderID => setOrderId(orderID)}
          />
        )}
      </div>
    );
}

export default OrdersComponent;
