import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./WigListing.css";
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ItemComponent from '../BusinessPage/Store/ItemComponent/ItemComponent'
import ReactDOM from "react-dom";
function discountPercentage(originalPrice, currentPrice) {
  var percent = currentPrice - originalPrice;
  percent = percent / originalPrice * 100
  return Math.abs(percent).toFixed(2);
}

const WigCard = (props) => {
  return (
    <>
      {props?.wig && <>
        {
          < >
            {props.wig.isListed &&
             
              <Col sm={3} key={props?.index + 'wigListing'} >
                <div className="card-container">
                  <Link
                    to={{
                      pathname: "/wig/" + props.wig.wigId,
                      state: { wig: props.wig }
                    }}
                  >
                    <div className="wigcard-img">
                      <img className={'p-0'} src={props.wig?.images[0]?.originalUrl} />
                    </div>
                    
                    <h3>{props.wig?.name}</h3>
                    <Row>
                      <div className="rating-box">
                        <Rating name="read-only" value={5} size="small" readOnly />
                        (15)
                      </div>
                    </Row>
                    <Row>
                      <p className="price-title">CA${props.wig.price} </p>
        
                    </Row>
                    <Row>
                      <div className="price-off" >
                      {props.wig?.originalPrice && ( <><h6 ><s>CA${props.wig?.originalPrice}.00</s> </h6><h6>({discountPercentage(props.wig?.originalPrice, props.wig?.price)}% off)</h6></>)}
                      </div>
                    </Row>
                  </Link>
                </div>
              </Col>
            }


          </>

        }
      </>}
    </>
  )
}

export default WigCard;