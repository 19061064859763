import React, { useEffect, useState, useRef, forwardRef, useCallback } from "react";
import "./Checkout.css"
import firestoreService from '../../services/firestoreService';
import { useSelector, useDispatch } from 'react-redux';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function OrderSummaryComponent(props) {
    useEffect(() => {

    }, [])


    return (
        <div>
            <h2>Order Summary</h2>
            <Col>
                <Row className='priceRow' >
                    <div>Subtotal</div>
                    <div className='priceSubtoal'>$100</div>

                </Row>

                <Row className='priceRow' >
                    <div>Tax</div>
                    <div className='priceSubtoal'>$100</div>

                </Row>

                <Row className='priceRow' >
                    <div>Delivery/Shipping</div>
                    <div className='priceSubtoal'>Free</div>

                </Row>

            </Col>
            <hr />
            <Col>
                <Row className='priceRow' >
                    <div>Total</div>
                    <div className='priceSubtoal'>$500</div>

                </Row>
            </Col>
            <hr />
            {props.cartItems.map(items => {
// console.log('yes',items.images[0].originalUrl)

                return (
                    <Col>
                        <Row className='priceRow' >
                            <Col> 
                            <div><img src={items.images[0].originalUrl} /> </div></Col>
                            <Col>  
                            <div className='priceSubtoal'>{items.name} </div>
                            <div className='priceSubtoal'>${items.price} </div>
                            </Col> 

                        </Row>
                    </Col>
                )

            })

            }
        </div>
    )
}

export default OrderSummaryComponent;