import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";


import './BusinessComponent.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";

import HomePage from "./HomePageComponent/HomePage.js";
import ClientComponent from "./ClientComponent/ClientComponent.js";
import WigsListing from "./WigListing/WigListing.js";
import WigCreatorListing from "./WigCreator/WigCreatorListing.js"
import PricingComponent from "./PricingComponent/PricingComponent.js";
import AboutUsSeller from './AboutUs/AboutUsSeller';
import AboutUs from './AboutUs/AboutUs';

import StoreComponent from "./BusinessPage/Store/StoreComponent.js";
import ItemComponent from "./BusinessPage/Store/ItemComponent/ItemComponent.js";
import ClientDashboardComponent from './ClientDashboard/ClientDashboardComponent'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { Image, Divider, Container, Icon, Modal, Button, Header } from "semantic-ui-react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import firebase from '../services/firebase'

import { useHistory } from "react-router-dom";
import logoImg from "../assets/logo.png";
import CartComponent from "./Cart/CartComponent";
import Auth from './Auth/Auth';
import CheckoutComponent from './Checkout/CheckoutComponent';
import FinalPaymentComponent from './Checkout/FinalPaymentComponent';
import { useSelector, useDispatch } from 'react-redux'
import { updateAuthModalActive } from '../redux/user/userSlice';


const CLIENT = "CLIENT"
const BUSINESSLIST = "BUSINESSLIST";
const HOMEPAGE = "HOMEPAGE";
const PRICING = "PRICING";
const PRODUCT = "PRODUCT";

function BusinessComponent(props) {
  const [component, setComponent] = useState(CLIENT);
  const [show, setShow] = useState(false);
  const [showTexture, setShowTexture] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showOrigin, setShowOrigin] = useState(false);
  const [showLength, setShowLength] = useState(false);
  const [showWigs, setShowWigs] = useState(false);
  const [modalState, setModalState] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const displayHeaderAndFooter = useSelector((state) => state.User.displayHeaderAndFooter)
  const dispalyHeader = useSelector((state) => state.User.dispalyHeader)
  const authModalActive = useSelector((state) => state.User.authModalActive)
  const loggedIn = useSelector((state) => state.User.loggedIn)
  const showDropdown = (e, name) => {
    setShow(!name);
    // setShowTexture(!name);
    // setShowColor(!showColor);
    // setShowOrigin(!showOrigin);
    // setShowLength(!showLength);
    setShowWigs(!showWigs);
  }
  const hideDropdown = (e, name) => {
    setShow(name);
    // setShowTexture(false);

    // setShowColor(false);
    // setShowOrigin(false);
    // setShowLength(false);
    setShowWigs(false);
  }

  useEffect(() => {
    console.log('Jim', firebase.auth().currentUser?.isAnonymous)
    console.log('user', firebase.auth().currentUser)
  }, [firebase.auth().currentUser])

  useEffect(() => {
    console.log('Jim', authModalActive)
    setModalState(authModalActive)

  }, [authModalActive])



  return (
    <BrowserRouter>
      <Modal
        className='modalSet'
        onClose={() => dispatch(updateAuthModalActive(false))}
        onOpen={() => dispatch(updateAuthModalActive(true))}
        open={modalState}
        closeOnDimmerClick={false}
      // trigger={<Button>Show Modal</Button>}
      >
        {/* <Modal.Header>Sign in/ Sign up</Modal.Header> */}
        <Modal.Content >

          <Modal.Description>

            <Auth closeModal={() => dispatch(updateAuthModalActive(false))} />

          </Modal.Description>

        </Modal.Content>

      </Modal>

      <div className="business-box">
        {displayHeaderAndFooter.header && <div className="header">
          <div className="top-header">
            <Navbar
              collapseOnSelect
              variant="light"
              expand="md"
              sticky="top"
            >
              <Navbar.Brand>
                <Row>
                  {/* <Col xs="1">
                    <Link  to={'/client'}> 
                      <img   src={logoImg} /> 
                    </Link>
                  </Col> */}
                  <Col>
                    <Link  to={'/home'}> 
                      <h2>Yornel</h2> 
                    </Link>
                  </Col>
                  <Col xs="auto">
                    {<Nav>
                      <Nav.Link onClick={() => {
                        if (firebase.auth()?.currentUser && firebase.auth().currentUser?.isAnonymous) {
                          dispatch(updateAuthModalActive(true))
                        } else {
                          history.push("/clientDashboard/profile");
                        }

                      }}>
                        {!loggedIn ? <p> Sign in</p> : <Icon name="user" />}
                      </Nav.Link>
                      <Nav.Link as={Link} to="/cart">
                        <Icon name="shopping cart" />
                      </Nav.Link>
                    </Nav>}
                  </Col>
                </Row>
              </Navbar.Brand>
            </Navbar>
          </div>

          <div className="bottom-header justify-content-center">
            <Navbar expand='sm'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
              >
                <Nav.Link  as={Link} to="/artist">
                   <h3> Sellers</h3>
                  </Nav.Link>
                <Nav activeKey="/home">
                  <Nav.Item>
                    <NavDropdown style={{fontSize:'18px',fontWeight:'bold'}} title="Wigs" id="basic-nav-dropdown"
                      show={showWigs}
                      onMouseEnter={() => setShowWigs(true)}
                      onMouseLeave={() => setShowWigs(false)}>
                      <NavDropdown.Item as={Link} to="/wigs/all">All Wigs</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/wigs/frontal">Frontal</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/wigs/closure">Closure</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/wigs/wave">Body Wave</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/wigs/straight">Straight</NavDropdown.Item>
                      {/* <NavDropdown.Item as={Link} to="/wigs/all">Straight</NavDropdown.Item> */}

                      <NavDropdown.Divider />
                    </NavDropdown>
                  </Nav.Item>
                  
                  {/* <Nav.Item>
                    <NavDropdown title="Sellers" id="basic-nav-dropdown"
                      show={showColor}
                      onMouseEnter={() => setShowColor(true)}
                      onMouseLeave={() => setShowColor(false)}>
                      <NavDropdown.Item href="/wigs/all"></NavDropdown.Item>
                     
                    </NavDropdown>
                  </Nav.Item> */}

                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        }
        <div className="body-container">
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            {/* <Route exact path="/home" component={HomePage} /> */}
            <Route exact path="/cart" component={CartComponent} />
            <Route exact path="/pricing" component={PricingComponent} />
            {/* <Route exact path="/policy" component={PolicyComponent} />
              <Route exact path="/terms" component={TermsOfService} /> */}
            <Route exact path="/home" component={ClientComponent} />
            <Route exact path="/clientDashboard/:location" component={ClientDashboardComponent} />
            <Route exact path="/wigs/:filter" component={WigsListing} />
            <Route exact path="/artist" component={WigCreatorListing} />
            {/* <Route exact path="/wigs/:filter" component={WigsListing} /> */}
            <Route exact path="/wig/:id" component={ItemComponent} />
            {/* <Route exact path="/cart/:stage" component={FinalPaymentComponent} /> */}
            <Route exact path="/payment/final" component={FinalPaymentComponent} />
            <Route exact path="/about/seller" component={AboutUsSeller} />
            <Route exact path="/about" component={AboutUs} />
            <Route
              exact
              path="/view-wigmaker-page/:id"
              render={(data) => (
                <StoreComponent id={data.match.params.id} {...props} />
              )}
            // component={() => <StoreComponent {...props} />}
            />
            <Route
              exact
              path="/item-page"
              // component={ItemComponent}
              render={(data) => (
                <ItemComponent id={data.match.params.id} {...props} />
              )}
            />

            {/* <Route
                render={function () {
                  return <p>Not found</p>;
                }}
              /> */}
          </Switch>
        </div>
        {/* {component === CLIENT && <ClientComponent />} 
          {component === HOMEPAGE && <HomePage />}
          {component === BUSINESSLIST && <BusinessListing />}
          {component === PRICING && <PricingComponent />} */}

        {displayHeaderAndFooter.footer && <div className="footer">
          <Container>
            <Row>
              <Nav activeKey="/about">
                <Nav.Item>
                  <Nav.Link as={Link} to="/home">
                    Blog
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/about">
                    About Us
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/about/seller">
                    Sell on Yornel
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/home">
                    Privacy Policy
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/home">
                    Terms of Service
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href={"mailto:lenroy@yornel.com"}>
                    Contact
                    </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>
            <Divider />
            <Row>
              <h3>Yornel</h3>
              <p> Copyright &copy; 2021 All rights reserved </p>
            </Row>
          </Container>
        </div>}
      </div>
    </BrowserRouter>
  );
}

export default BusinessComponent;