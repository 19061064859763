import React from 'react';
import { Component, useState, useRef, useEffect } from 'react';
import { Button, Dropdown, Header, Table } from 'semantic-ui-react';
import Modal from "react-bootstrap/Modal";


const OrdersDetailsModal = ({ show, item,updateShow }) => {
    const [showItemDetails, setShowItemDetails] = useState(show);



    useEffect(() => {
        console.log('ordr detils show',show)
        setShowItemDetails(show)
    }, [show])

    function valueUpdated(obj) {

        return (
            obj.valueUpdated ? <p style={{ color: 'orange' }} >(You Changed)</p> : ''
        )
    }


    const handleCloseItemDetails = () => {
        setShowItemDetails(false);
        updateShow()
    };

    return (
        <Modal
            show={showItemDetails}
            onHide={() => handleCloseItemDetails()}
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Wig Details
</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table bordered hover size="sm">
                    <tbody>
                        {item.hairOrigin && <tr>
                            <td title="Hair Origin">Hair Origin</td>
                            <td>{item.hairOrigin}{valueUpdated(item.hairOrigin)}</td>
                        </tr>}
                        <tr>
                            <td title="Length">Length</td>
                            <td>{item.length.label}{valueUpdated(item.length)}</td>
                        </tr>
                        <tr>
                            <td title="Texture">Texture</td>
                            <td>{item.texture.label}{valueUpdated(item.texture)}</td>
                        </tr>
                        <tr>
                            <td title="Density">Baby hairs</td>
                            <td>{item.babyHairs.label}{valueUpdated(item.babyHairs)}</td>
                        </tr>
                        <tr>
                            <td title="Cap Color">Cap Color</td>
                            <td>{item.capColor.label}{valueUpdated(item.capColor)}</td>
                        </tr>
                        <tr>
                            <td title="Cap Color">Color</td>
                            <td>{item.color.label}{valueUpdated(item.color)}</td>
                        </tr>
                        <tr>
                            <td title="Cap Construction">Cap Construction</td>
                            <td>{item.capConstruction.label}{valueUpdated(item.capConstruction)}</td>
                        </tr>
                        <tr>
                            <td title="Circumference">Circumference</td>
                            <td>{item.circumference.label}{valueUpdated(item.circumference)}</td>
                        </tr>
                        <tr>
                            <td title="Density">Density</td>
                            <td>{item.density.label}{valueUpdated(item.density)}</td>
                        </tr>
                        <tr>
                            <td title="Elastic Bands">Elastic Band</td>
                            <td>{item.elasticBand.label}{valueUpdated(item.elasticBand)}</td>
                        </tr>
                        <tr>
                            <td title="Elastic Bands and Combs">Elastic Bands and Combs</td>
                            <td>{item.elasticBandsAndCombs}{valueUpdated(item.elasticBandsAndCombs)}</td>
                        </tr>
                        <tr>
                            <td title="Extention Length 1">Extention Length 1</td>
                            <td>{item.extentionLength1.label}{valueUpdated(item.extentionLength1)}</td>
                        </tr>
                        <tr>
                            <td title="Extention Length 2">Extention Length 2</td>
                            <td>{item.extentionLength2.label}{valueUpdated(item.extentionLength2)}</td>
                        </tr>
                        <tr>
                            <td title="Extention Length 3">Extention Length 3</td>
                            <td>{item.extentionLength3.label}{valueUpdated(item.extentionLength3)}</td>
                        </tr>
                        <tr>
                            <td title="Glueless">Glueless</td>
                            <td>{item.glueless.label}{valueUpdated(item.glueless)}</td>
                        </tr>
                        {/* <tr>
            <td title="Gluess">Gluess</td>
            <td>{item.gluess}</td>
          </tr> */}
                        <tr>
                            <td title="Wig Combs">Wig Combs</td>
                            <td>{item.wigCombs.label}{valueUpdated(item.wigCombs)}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseItemDetails()}>
                    Close
</Button>

            </Modal.Footer>
        </Modal>)

}

export default OrdersDetailsModal;