import { createSlice } from '@reduxjs/toolkit'
import StateManager from 'react-select';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        orders: [],
        userInfo: {},
        loggedIn: false,
        userId: '',
        cart: [],
        currentOrder: {},
        cartStylistInfo: {},
        selectedInstallation: {},
        displayHeaderAndFooter: {footer:true,header:true},
        displayHeader: true,
        loading: false,
        orderNotes: '',
        controlValues: {},
        authModalActive: false

    },
    reducers: {
        updateSelectedInstallation: (state, action) => {
            state.selectedInstallation = action.payload;
        },
        updateCartStylistInfo: (state, action) => {
            state.cartStylistInfo = action.payload;
        },
        updateCurrentOrder: (state, action) => {
            state.currentOrder = action.payload;
        },
        updateOrders: (state, action) => {
            state.orders = action.payload;
        },
        updateUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        updateLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        updateUserId: (state, action) => {
            state.userId = action.payload;
        },
        updateCart: (state, action) => {
            state.cart = action.payload;
        },
        getUserId: (state) => {
            return state.userId
        },
        updateDisplayHeaderAndFooter: (state, action) => {
            state.displayHeaderAndFooter = action.payload
        },
        updateDisplayHeader: (state, action) => {
            state.displayHeader = action.payload
        },
        updateLoading: (state, action) => {
            state.loading = action.payload
        },
        updateOrderNotes: (state, action) => {
            state.orderNotes = action.payload
        },
        updateControlValues: (state, action) => {
            state.controlValues = action.payload
        },
        updateAuthModalActive: (state, action) => {
            state.authModalActive = action.payload
        },
    }
})
//Action Creators are generated for each reducer function
export const { updateLoggedIn, updateUserInfo, updateOrders, updateUserId, updateCart,
    getUserId, updateCurrentOrder, updateCartStylistInfo, updateSelectedInstallation,
    updateDisplayHeaderAndFooter, updateLoading, updateOrderNotes, updateControlValues, updateAuthModalActive,updateDisplayHeader } = userSlice.actions

export default userSlice.reducer