import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import googleBtn from '../../assets/googleSignInBtn.png';
import firebase, { auth } from '../../services/firebase'

const Login = (props) => {
    useEffect(() => {

    }, [])

    function signInWithGoogle() {
        var provider = new firebase.auth.GoogleAuthProvider();
        // var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().currentUser.linkWithRedirect(provider);
            
 
    }

    //Todo format, also add uploaded image
    return (
        <>
            <Formik
                initialValues={{ email: '', password: '' }}
            >
                {({ errors, touched, handleChange, values }) => (

                    <Form>
                       
                            <Button onClick={() => { signInWithGoogle() }} variant='link' >
                                <img src={googleBtn} />

                            </Button>
                            <hr/>
                             <Col lg={12} sm={3}>
                                 <h3 style={{textAlign:'center'}}>Login</h3>
                            <FormLabel ><h3>Email</h3></FormLabel>
                            <FormControl className={'p-3 m-3'} name={'email'}
                                type="text"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange} />
                            <FormLabel ><h3>Password</h3></FormLabel>
                            <FormControl className={'p-3 m-3'} name={'password'}
                                type="text"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange} />


                            <Button style={{float:'right'}} type="submit">Login</Button>
                        </Col>
                    </Form>

                )}
            </Formik>

           
        </>
    )
}

export default Login;