import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Rating } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from 'moment'
import Login from './Login';
import SignUp from './SignUp';
import {updateAuthModalActive} from '../../redux/user/userSlice'
import { useSelector, useDispatch } from 'react-redux'

const LOGIN = 'LOGIN'
const SIGNUP = 'SIGNUP'
const Auth = (props) => {
    useEffect(() => {
        console.log('hahahah', props.reviews)

    }, [])

   function switchView(view){
    setCurrentView(view)
    }
    const [currentView, setCurrentView] = useState(LOGIN)
    const dispatch = useDispatch();

    //Todo format, also add uploaded image
    return (
        <>
            <span className='p-3 mb-4'>
                {currentView === LOGIN && <Login closeModal={props.closeModal}/>}
                {currentView === SIGNUP && <SignUp closeModal={props.closeModal} />}
            </span>
           { currentView === LOGIN ? <Col>
                <p style={{ textAlign: 'center' }}>Are you a new user? <Button variant='link' className='p-0 m-0 ml-2' onClick={()=>switchView(SIGNUP)}>Sign Up</Button> </p>
            </Col>:<Col>
                <p style={{ textAlign: 'center' }}>Login <Button variant='link' className='p-0 m-0 ml-2' onClick={()=>switchView(LOGIN)} >Login?</Button> </p>
            </Col>}
            <Button variant={'link'} onClick={()=>{dispatch(updateAuthModalActive(false))}}>Continue As Guest</Button>


        </>
    )
}

export default Auth;